.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    color: #ffffff;
    padding: .75rem 1rem;
    z-index: 1200;
}
@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
}
.cookie-bar__text {
    display: inline;
}
@media screen and (min-width: 768px) {
    .cookie-bar__text {
        display: block;
        flex: 0 1 auto;
    }
}

.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline;
}
@media screen and (min-width: 768px) {
    .cookie-bar__detail {
        flex: auto;
        margin-left: 1rem;
    }
}

.cookie-bar__decline {
    color: white;
    background: none;
}

.cookie-bar__decline:hover{
    color: var(--text-light-grey);
    background: none;
}

.cookie-bar__detail-btn {
    display: inline;
    text-decoration: underline;
    vertical-align: inherit;
    cursor: pointer;
    margin-right: .25em;
    color: #ffffff;
    border: none;
    background: none;
    padding: 0;
}
.cookie-bar__detail-btn:hover,
.cookie-bar__detail-btn:focus,
.cookie-bar__detail-btn:active {
    color: #ffffff;
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        flex: none;
        margin-left: 1rem;
    }
}
.cookie-bar__accept {
    margin-left: .75rem;
}
@media screen and (max-width: 767px) {
    .cookie-bar__accept{
        float: right;
    }
}

/* modal */
.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.5rem;
    /*font-weight: bold;*/
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}
.cookie-modal__close {
    display: inline-block;
    border: none;
    background: none;
    opacity: .7;
    transition: opacity 120ms;
    float: right;
    font-size: 1.5em;
}
.cookie-modal__close:hover,
.cookie-modal__close:focus,
.cookie-modal__close:active {
    opacity: 1;
}

/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--bg-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--bg-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(26px);
}
