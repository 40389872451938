.accordion {
    border-top:1px solid #dfdfdf;
}
.accordion--no-border-top {
    border-top:0;
}
.detail-site-main__item .accordion .wysiwyg {
    border-bottom:0;
}
.detail-site-main__item .panel-group.accordion {
    margin-bottom:5px;
}
.accordion .panel-title>a {
    color: #424242;
}
.accordion.panel-group .panel + .panel {
    margin-top:0;
}
.accordion.panel-group .panel {
    border-radius: 0;
}
.accordion .panel-title .icon {
    position: absolute;
    right:0;
    top:20px;
    font-size:14px;
    margin:0;
    color:var(--text-primary);
}
.accordion .panel-title > a[aria-expanded="true"] > .icon {
    top:25px;
}
.accordion .panel-title > a[aria-expanded="true"] > .icon:before {
    content: " ";
    display: block;
    width:14px;
    height:2px;
    background-color:var(--text-primary);
}
.accordion .panel-default>.panel-heading {
    position: relative;
    color: #424242;
    background-color: transparent;
    padding:0;
    line-height:1;
    border:0;
}
.accordion .panel-default>.panel-heading a {
    display:block;
    padding: 15px 0;
}

.accordion .panel-default>.panel-heading a:after,
.accordion .panel-collapse.collapse.in:after {
    z-index:40;
    position: absolute;
    left:25px;
    bottom:-8px;
    content:' ';
    display:block;
    width:16px;
    height:16px;
    transform: rotate(-225deg);
    background:#fff;
    border:1px solid #dfdfdf;
    border-width:1px 1px 0 0;
    transition: all 0.5s linear;
}

.accordion .panel-default>.panel-heading a[aria-expanded="true"]:after {
    opacity:0;
}
.accordion .panel-collapse.collapse.in:after {
    transform: rotate(-45deg);
}
.accordion .panel-default {
    position: relative;
    border-width:0 0 1px 0;
    border-color:#dfdfdf;
    box-shadow: none;
    border-radius: 0;
}
.accordion.panel-group .panel-heading+.panel-collapse>.panel-body, .accordion.panel-group .panel-heading+.panel-collapse>.list-group {
    border:0;
}
.accordion .panel-body {
    padding: 0 0 15px 0;
}


@media screen and (min-width: 768px) {
    .accordion .panel-default>.panel-heading a {
        padding: 30px 0;
    }
    .accordion .panel-body {
        padding: 0 0 25px 0;
    }
    .accordion .panel-title .icon {
        top:35px;
    }
    .accordion .panel-title > a[aria-expanded="true"] > .icon {
        top:40px;
    }
}


@media screen and (min-width: 1200px) {
    .accordion .panel-title .icon {
        top:40px;
    }
    .accordion .panel-title > a[aria-expanded="true"] > .icon {
        top:45px;
    }
}

/* result bar in accordions */
@media screen and (min-width: 768px) {
    .accordion .result-bar {
        margin:5px 0 30px 0;
    }
}