.ajax-load-container.is-loading {
     min-height: 50vh;
     position: relative;
}
.js-filter-form-result.ajax-load-container.is-loading {
    opacity: 0.5;
    transition: opacity 0.15s;
}


.ajax-load-container.is-loading:before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    border: 5px solid;
    border-color: #002F5D #002F5D transparent #002F5D;
    border-style: solid;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 100px;
    margin-left: -20px;
    animation: animPreloader 0.75s linear infinite;
}

@media screen and (min-width: 992px) {
    .ajax-load-container .teaser {
        animation-name: fadeIn;
        animation-duration: 0.25s;
        animation-timing-function: ease-out;
        animation-delay: 0.15s;
        animation-direction: alternate;
        animation-iteration-count: 1;
        animation-fill-mode: backwards;
    }
}

.is-loading-container:before {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    border: 5px solid;
    border-color: #002F5D #002F5D transparent #002F5D;
    border-style: solid;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    animation: animPreloader 0.75s linear infinite;
}