.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 12px 10px;
    font-size: 17px;
    line-height: 1.42857143;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    z-index: 20;
    font-family: var(--font-default);
}

.btn.back-button {
    font-size: 14px;
}

.btn.back-button:hover,
.btn.back-button:focus{
    background:#eee;
}

.wysiwyg .btn {
    text-decoration: none;
}
.btn-has-border {
    border: 2px solid;
}

/* btn-primary */
.btn-primary {
    position: relative;
    background:#004181;
    font-family: var(--font-headline);
    padding: 15px 10px;
    height: 54px;
    margin-left: 30px;
    margin-right: 30px;
   /* transition: all 0.2s ease;*/
}
.btn-primary:hover,
.btn-primary:focus{
    color:#fff;
}
.btn-primary:before,
.btn-primary:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 27px 27px 0;
    border-color: transparent #004181 transparent transparent;
    transform: none;
    left: -26px;
    top: 0;
    height: 54px;
}
.btn-primary.btn:after {
    border-width: 27px 0 27px 27px;
    border-color: transparent transparent transparent #004181;
    left: auto;
    right: -26px;
}



/* btn-primary -- LG */
.btn-primary.btn-lg {
    height: 60px;
    padding: 8px 15px;
}
.btn-primary.btn-lg:before,
.btn-primary.btn-lg:after {
    border-width: 30px 30px 30px 0;
    left: -29px;
    height: 60px;
}
.btn-primary.btn-lg:after {
    border-width: 30px 0 30px 30px;
    left: auto;
    right: -29px;
}

/* btn-primary -- SM */
.btn-primary.btn-sm {
    height: 36px;
    padding: 8px 15px;

}
.btn-primary.btn-sm:before,
.btn-primary.btn-sm:after {
    border-width: 18px 18px 18px 0;
    left: -17px;
    height: 36px;
}
.btn-primary.btn-sm:after {
    border-width: 18px 0 18px 18px;
    left: auto;
    right: -17px;
}
/* btn-primary -- XS */
.btn-primary.btn-xs {
    height: 26px;
    padding: 4px 8px;
    margin-left: 15px;
    margin-right: 15px;
}
.btn-primary.btn-xs:before,
.btn-primary.btn-xs:after {
    border-width: 13px 13px 13px 0;
    left: -13px;
    height: 26px;
}
.btn-primary.btn-xs:after {
    border-width: 13px 0 13px 13px;
    left: auto;
    right: -13px;
}



@media screen and (max-width: 767px) {
    .btn.back-button {
        font-size: 13px;
    }
    .btn.btn-primary {
        height: 36px;
        padding: 8px 15px;
        font-size: 13px;
    }
    .btn.btn-primary:before,
    .btn.btn-primary:after {
        border-width: 18px 18px 18px 0;
        left: -18px;
        height: 36px;
    }
    .btn.btn-primary:after {
        border-width: 18px 0 18px 18px;
        left: auto;
        right: -18px;
    }
}



/* btn-default
-------------------------- */
.btn-default {
    color: var(--text-primary);
    background: #f8f8f8;
}
.btn-default:focus,
.btn-default:hover,
.btn-default.focus {
    color: var(--text-primary);
}


/* btn-pattern */
.btn.btn-pattern {
    position: relative;
    padding:10px 15px 10px 40px;
    color: var(--text-primary);
    font-family: var(--font-default);
    background: url(/static/img/pattern.jpg);
    text-decoration:none;
}
.btn.btn-pattern .icon {
    position: absolute;
    top: 10px;
    left:10px;
    font-size:20px;
    transition: all 0.2s linear;
}
.btn.btn-pattern:focus,
.btn.btn-pattern:hover,
.btn.btn-pattern.focus {
    color:#000;
}
@media screen and (min-width: 768px) {
    .btn.btn-pattern {
        padding:18px 25px 18px 70px;
    }
    .btn.btn-pattern .icon {
        top: 15px;
        left:25px;
        font-size:30px;
    }
}

@media screen and (max-width: 767px) {
    .btn.xs-center {
        margin-left:auto;
        margin-right:auto;
    }
}

.btn.text-left { text-align: left; }
/*.btn:focus,*/
/*.btn:active:focus,*/
/*.btn.active:focus,*/
/*.btn.focus,*/
/*.btn:active.focus,*/
/*.btn.active.focus {*/
    /*outline: none;*/
/*}*/
.btn:hover,
.btn:focus,
.btn.focus {
    text-decoration: none;
}

.btn.disabled,
.btn[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn .icon {
    vertical-align: baseline;
    font-size: 80%;
}


a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}




/* btn-success */
.btn-success {
    color: #ffffff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}
.btn-success:focus,
.btn-success.focus {
    color: #ffffff;
    background-color: #449d44;
    border-color: #255625;
}
.btn-success:hover {
    color: #ffffff;
    background-color: #449d44;
    border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
    color: #ffffff;
    background-color: #449d44;
    border-color: #398439;
}

.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
}
.btn-success .badge {
    color: #5cb85c;
    background-color: #ffffff;
}

.btn-link {
    color: var(--text-primary);
    font-weight: normal;
    border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    border-color: transparent;
}
.btn-link.btn-link--border,
.btn-link.btn-link--border:hover,
.btn-link.btn-link--border:focus,
.btn-link.btn-link--border:active {
    border: 1px solid #bebebe;
}
.btn-link:hover,
.btn-link:focus {
    color: var(--text-primary);
    text-decoration: underline;
    background-color: transparent;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 17px 25px;
   /* font-size: 20px;*/
    line-height: 1.3333333;
}
.btn-sm,
.btn-group-sm > .btn {
    padding: 7px 16px;
  /*  font-size: 14px;*/
    line-height: 1.5;
}
.btn-xs,
.btn-group-xs > .btn {
    padding: 3px 8px;
  /*  font-size: 12px;*/
    line-height: 1.5;
}
.btn-block {
    display: block;
    width: 100%;
}

/* BUTTON GROUPS
 ---------------------------- */
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
/*.btn-group > .btn {*/
    /*margin: 4px;*/
/*}*/
.btn-group .btn {
    margin-right:30px;
    margin-bottom:10px;
}
@media screen and (max-width: 767px) {
    .btn-group .btn {
        margin-right:10px;
    }
}

.btn-white,
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    background-color: #ffffff;
    color: var(--text-primary);
}