.wysiwyg a,
.default-link {
    color:var(--text-primary);
    text-decoration: none;
}
.wysiwyg a:hover,
.default-link:hover {
    /*color:var(--text-default);*/
    text-decoration:underline;
}
/*.wysiwyg strong a {*/
    /*text-decoration: none;*/
/*}*/

.wysiwyg p {
    margin-bottom: 24px;
}
.wysiwyg ul,
.wysiwyg ol {
    margin-bottom: 24px;
    padding-left: 17px;
}
.wysiwyg ul {
    list-style-type: circle;
}
.wysiwyg ol {
    list-style-type: upper-roman;
}

@media screen and (min-width: 768px) {
    .wysiwyg ol,
    .wysiwyg ul {
        padding-left: 23px;
    }
    /*.wysiwyg p,*/
    /*.wysiwyg ul,*/
    /*.wysiwyg ol {*/
        /*margin-bottom: 45px;*/
    /*}*/
}
.wysiwyg p:last-child,
.wysiwyg ul:last-child,
.wysiwyg ol:last-child {
    margin-bottom: 0;
}


.wysiwyg h1:first-child,
.wysiwyg h2:first-child,
.wysiwyg h3:first-child,
.wysiwyg h4:first-child,
.wysiwyg h5:first-child,
.wysiwyg h6:first-child {
    margin-top: 0;
}
@media screen and (max-width: 767px) {
    .wysiwyg,
    .wysiwyg address {
        line-height: calc(27/17);
    }
}
@media screen and (min-width: 768px) {
    .wysiwyg,
    .wysiwyg address {
        line-height: calc(29/17);
    }
}

/* lead */
@media screen and (min-width: 768px) {
    .wysiwyg.lead {
        font-size: 24px;
        font-weight: 400;
        line-height: 40px;
    }
}


@media screen and (max-width: 767px) {
    .wysiwyg .content-block {
        margin: 0;
    }
}
