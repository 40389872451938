.text-primary {
     color: var(--text-primary);
}
.text-secondary {
    color: var(--text-secondary);
}
.text-dark {
    color: var(--text-dark);
}
.text-muted {
    color: var(--text-muted);
}
.text-default {
    color: var(--text-default);
}
.text-white {
    color:#fff;
}

.font-italic {
    font-style: italic;
}
.font-bold, strong, b {
    font-weight:normal;
    font-family: var(--font-bold);
}
body.lang-ru font-bold,
body.lang-ru strong,
body.lang-ru b {
    font-weight: 700;
}
/* FONTS */
.font-headline, .font-headline-bold {
    font-family: var(--font-headline);
}


/* hl-with-lines */
.hl-with-lines {
    clear: both;
}
.hl-with-lines h2 {
    display: inline-block;
    margin: 0;
    position: relative;
    text-transform: none;
    color: var(--text-secondary);
}
.hl-with-lines h2:before,
.hl-with-lines h2:after {
    content: '';
    position: absolute;
    left: -100px;
    width: 75px;
    top: 50%;
    height: 1px;
    background: #dfdfdf;
}
.hl-with-lines h2:after {
    right: -100px;
    left: auto;
    width: 75px;
}


/* HELPER CLASSES
 ------------------------- */

@media screen and (max-width: 767px) {
    .xs-block { display: block; }
    .xs-text-center { text-align: center; }
    .xs-nopadding { padding: 0 !important; }
}
@media screen and (min-width: 768px) {
    .xs-on-block {
        display: block;
    }
}
.no-margin.no-margin {
     margin: 0;
}
.no-padding.no-padding {
    padding: 0;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



/* FLEX - HELPER
 ---------------------- */
.flex-wrapper {
    display: flex;
}
.flex-wrapper.flex-wrapper--align-center {
    align-items: center;
}
.no-text-transform.no-text-transform {
    text-transform: none;
}

/* MARGINS -- PADDINGS
 -------------------------- */
.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 5px; }
.mt-20.mt-20 { margin-top: 10px; }
.mt-30 { margin-top: 15px; }
.mt-40 { margin-top: 20px; }
.mt-50.mt-50 { margin-top: 25px; }
.mt-80 { margin-top: 40px; }
.mt-100 { margin-top: 50px; }

.pt-20 {
    padding-top: 5px;
}
.pt-0{
    padding-top:0 !important;
}

.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 5px; }
.mb-20 { margin-bottom: 10px; }
.mb-30 { margin-bottom: 15px; }
.mb-40 { margin-bottom: 20px; }
.mb-50 { margin-bottom: 25px; }

.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-20 { margin-right: 10px; }
.mr-50 { margin-right: 25px; }


@media screen and (min-width: 768px) {
    .mt-5 { margin-top: 10px; }
    .mt-10 { margin-top: 10px; }
    .mt-20.mt-20 { margin-top: 15px; }
    .mt-30 { margin-top: 20px; }
    .mt-40 { margin-top: 30px; }
    .mt-50.mt-50 { margin-top: 40px; }
    .mt-80 { margin-top: 70px; }
    .mt-100 { margin-top: 85px; }
    .mb-5 { margin-bottom: 5px; }
    .mb-10 { margin-bottom: 10px; }
    .mb-20 { margin-bottom: 15px; }
    .mb-30 { margin-bottom: 20px; }
    .mb-40 { margin-bottom: 30px; }
    .mb-50 { margin-bottom: 30px; }
    .ml-5 { margin-left: 5px; }
    .ml-10 { margin-left: 10px; }
    .mr-5 { margin-right: 5px; }
    .mr-10 { margin-right: 10px; }
    .mr-20 { margin-right: 20px; }
    .mr-50 { margin-right: 40px; }

    .pt-20 { padding-top: 10px; }
}
@media screen and (min-width: 1200px) {
    .mt-5 { margin-top: 10px; }
    .mt-10 { margin-top: 10px; }
    .mt-20.mt-20 { margin-top: 20px; }
    .mt-30 { margin-top: 30px; }
    .mt-40 { margin-top: 40px; }
    .mt-50.mt-50 { margin-top: 50px; }
    .mt-80 { margin-top: 80px; }
    .mt-100 { margin-top: 100px; }
    .mb-5 { margin-bottom: 5px; }
    .mb-10 { margin-bottom: 10px; }
    .mb-20 { margin-bottom: 20px; }
    .mb-30 { margin-bottom: 30px; }
    .mb-40 { margin-bottom: 40px; }
    .mb-50 { margin-bottom: 40px; }
    .ml-5 { margin-left: 5px; }
    .ml-10 { margin-left: 10px; }
    .mr-5 { margin-right: 5px; }
    .mr-10 { margin-right: 10px; }
    .mr-50 { margin-right: 50px; }

    .pt-20 { padding-top: 20px; }
}
@media screen and (max-width: 767px) {

    .xs-no-padding-bottom { padding-bottom:0 !important; }
    .xs-no-padding-top { padding-top:0 !important; }

    .row--has-xs-colmargin > .col + .col {
        margin-top: 15px;
    }
    .xs-mb-15.xs-mb-15 {
        margin-bottom: 15px;
    }
    .xs-mt-15 {
        margin-top: 15px;
    }
}


/* BACKGROUNDS */
.bg-primary {
    color: #fff;
    background-color:var(--bg-primary);
}
.bg-white {
    background-color:#fff;
}
a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #286090;
}
.bg-muted {
    background: var(--bg-muted);
}

.bg-black{
    background: #000;
    color: #fff;
}

.bg-pattern-blog {
    background:url(/static/build/img/pattern-blog.png);
}



/* FLEXIBLE BOX
 ----------------------------- */
.flex {
    display: flex;
}
.flex--center {
    align-items: center;
}
@media screen and (max-width: 767px) {
    .flex--disable-mobile {
        display: block;
    }
}



/* MISC
 ------------------- */
.no-border.no-border {
    border:0;
}
.isClickable {
    cursor: pointer;
}
.lh-1 {
    line-height: 1
}
@media screen and (min-width: 1200px) {
    .w-50-perc {
        width: 50%;
    }
}
.relative {
    position: relative;
}

@media screen and (max-width: 767px) {
    .mapsContainer.embed-responsive-2by1 {
        padding-top: 100%;
    }
}
/* FIGURE - FIGCAPTIONS
 ------------------------- */
#main figure figcaption,
.headervideo .figcaption{
    color: #fff;
    font-size: 12px;
    padding: 10px;
    background-color: rgba(66, 66, 66, 0.75);
    position: absolute;
    left: 0;
    bottom: 0;
    letter-spacing: .5px;
}
@media screen and (max-width: 767px) {
    #main figure figcaption,
    .headervideo .figcaption {
        padding: 5px;
        font-size: 11px;
    }
}


/* readmore */

.readmore {
    height:220px;
    overflow: hidden;
    position: relative;
}
.readmore:not(.readmore--opened):after {
    content:'';
    height:50px;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.readmore--opened {
    height:auto;
    overflow:visible;
}
.readmore-btn {
    margin:15px 0 25px 0;
}
.readmore-btn a {
    color:var(--text-primary);
}
.readmore-btn a:hover,
.readmore-btn a:focus {
    text-decoration: underline;
}
@media screen and (min-width: 768px) {
    .readmore-btn {
        margin:20px 0 35px 0;
    }
}


/* opening */
.opening {
    padding-left:30px;
    position: relative;
}
.opening .icon {
    position: absolute;
    left:4px;
    top:3px;
    color:#fff;
}
.opening:before {
    content:' ';
    display: block;
    width:20px;
    height:20px;
    border-radius:50%;
    position: absolute;
    left:0;
    top:4px;
}
.opening--opened:before {
    background:#72c660;
}
.opening--closed:before {
    background:var(--bg-primary);
}
.opening--closed .icon {
    top:13px;
    left:5px;
}
.opening--closed .icon:before {
    content:' ';
    width:10px;
    height:2px;
    display: block;
    background:#fff;
}
/*.closed .icon:before {*/
    /*content: "-";*/
/*}*/
/*.opening--closed .icon:before {*/
    /*content:'-';*/
/*}*/


/* MULTICOLUMS
-------------------------- */
@media screen and ( min-width: 768px ) {
    .multicolumn_2 {
        column-count: 2;
        column-gap: 30px;
    }
}


@media screen and ( min-width: 992px ) {
    .container-narrow {
        padding:0 50px;
    }
    .container-narrow--blog {
        padding: 0 35px;
    }
}
@media screen and ( min-width: 1200px ) {
    .container-narrow {
        padding:0 130px;
    }
    .container-narrow--blog {
        padding: 0 90px;
    }
}
@media screen and ( min-width: 1400px ) {
    .container-wide {
        width: 1388px;
    }
}




/* hide google conversion frame */
iframe[name=google_conversion_frame] {
    position: absolute;
    left:-19999px;
    bottom:0;
}

.anchor-target {
    padding-top: 100px;
    margin-top: -100px;
}

[hidden] {
    display: none !important;
}