/* hr with svg */
.hr--has-svg, .hr, hr {
    display: block;
}
.hr--has-svg, .hr, hr {
    margin: 25px 0;
    border: 0;
    border-top: 1px solid #dfdfdf;
    box-sizing: content-box;
    height: 0;
    position: relative;
    clear: both;
}
.hr--has-svg > .svg-container {
    position: absolute;
    right: 0;
    top: -40px;
    width: 75px;
    height: 75px;
    /*pointer-events: none;*/
}
@media screen and (max-width: 767px) {
    body.is-portal .hr--has-svg, .hr, hr {
        margin: 40px 0;
    }
}
@media screen and (min-width: 768px) {
    .hr--has-svg, .hr, hr {
        margin: 30px 0;
    }
    body.is-portal .hr--has-svg, .hr, hr {
        margin: 50px 0;
    }
}

@media screen and (min-width: 1200px) {
    body.is-portal .hr--has-svg, .hr, hr {
          margin: 85px 0;
    }
    .hr--has-svg, .hr, hr {
        margin: 40px 0;
    }

}

/* svg - left-center-rigt */
.hr--has-svg > .svg-container.svg-container--center {
    right: 50%;
    transform: translateX(50%);
}
.hr--has-svg > .svg-container.svg-container--left {
    right: auto;
    left: 0;
}

/* svg - colors
--------------- */
/* white */
.hr--has-svg.hr--has-svg--white {
    border-top: 1px solid #ffffff;
}
.hr--has-svg.hr--has-svg--white .svg-container svg g {
    stroke: rgb(255, 255, 255);
}


/* svg - sizes */

.hr--has-svg > .svg-container.svg-container--small {
    top: -30px;
    width: 60px;
    height: 60px;
}
@media screen and (max-width: 767px) {

    .hr--has-svg > .svg-container {
        top: -25px;
        width: 50px;
        height: 50px;
    }
    .hr--has-svg > .svg-container.svg-container--small {
        top: -20px;
        width: 40px;
        height: 40px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .hr--has-svg > .svg-container {
        top: -35px;
        width: 70px;
        height: 70px;
    }
    .hr--has-svg > .svg-container.svg-container--small {
        top: -25px;
        width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 1200px) {
    .hr--has-svg > .svg-container {
        top: -50px;
        width: 100px;
        height: 100px;
    }
    .hr--has-svg > .svg-container.svg-container--small {
        top: -30px;
        width: 60px;
        height: 60px;
    }
}



/* skyline */
.hr--has-svg.hr--has-skyline > .svg-container {
    width: 400px;
    top: auto;
    height: auto;
    transform: translateX(50%) translateY(-100%);
}

/* just arrow */
.hr--has-arrow:before {
    z-index:40;
    position: absolute;
    left:50%;
    margin-left:-8px;
    bottom:-8px;
    content:' ';
    display:block;
    width:16px;
    height:16px;
    transform: rotate(-225deg);
    background:#fff;
    border:1px solid #dfdfdf;
    border-width:1px 1px 0 0;
}
@media screen and (min-width: 768px) {
    .hr--has-arrow:before {
        margin-left:-17px;
        bottom:-17px;
        width:34px;
        height:34px;
    }

}

