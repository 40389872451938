.infoBox {
    background: #ffffff;
}
.infoBox > img {
    visibility: hidden;
    position: absolute !important;
    right: 0;
    top: 0;
    background: green;
    z-index: 1;
    height: 0;
    padding-top: 30px;
    width: 30px;
    overflow: hidden;
    margin: 0;
}
.infoBox a {
    text-decoration: underline;
    color:var(--text-primary);
}
.infoBox a:hover,
.infoBox a:focus{
    text-decoration: none;
}
.infoBox__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    z-index: 40;
    background: var(--bg-primary);
    color:#fff;
    border: none;
}

.infoBox__teaser.infoBox__teaser {
    padding: 0;
    margin: 0;
    font-size:14px;
}