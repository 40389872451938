@media screen and (max-width: 767px) {
    .row--related-content .slick-thumb-arrow {
        width: 40px;
        height: 40px;
        color:#fff;
        text-decoration: none;
    }
    .row--related-content .slick-thumb-arrow .icon {
        position: absolute;
        top:17px;
        right: 23px;
    }
    .row--related-content .slick-thumb-arrow--next .icon {
        right: auto;
        left:23px;
    }
    .row--related-content .slick-thumb-arrow.slick-thumb-arrow--prev:before,
    .row--related-content .slick-thumb-arrow.slick-thumb-arrow--next:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        background: #002F5D;
        transform: rotate(-45deg);
        transform-origin: center;
        width: 50px;
        height: 50px;
        left: -36px;
        top: 0;
        z-index: -1;
    }
    .row--related-content .slick-thumb-arrow.slick-thumb-arrow--next:after {
        left: auto;
        right: -36px;
        top: 0;
        z-index: -1;
    }

}