.sticky-sidebar {
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    z-index:1200;
    pointer-events: none;
}
.sticky-sidebar ul {
    width:100%;
}
.sticky-sidebar li {
    width: 33.333333%;
    float:left;
    display: inline-block;
    position: relative;
    height:50px;
    pointer-events:auto;
    border-right:1px solid #fff;
}
@media screen and ( max-width: 767px ) {
    .sticky-sidebar li:last-child {
        display: none;
    }
}
.sticky-sidebar a {
    color:#fff;
    font-size:13px;
    position: relative;
    text-align: left;
    display:block;
    height:50px;
    background:var(--bg-primary);
}
.sticky-sidebar a span{
    display: inline-block;
    padding-right:6px;
    position: absolute;
    left:38px;
    top:50%;
    transform:translateY(-50%);
}
.sticky-sidebar a .icon {
    position: absolute;
    top:17px;
    left:9px;
    font-size:18px;
}
.sticky-sidebar a .icon-besucher {
    top:15px;
    font-size:20px;
}
.sticky-sidebar li:hover a {
    background:#fff;
    color:var(--text-primary);
}


/* desktop */
@media screen and ( min-width: 768px ) {
    .sticky-sidebar {
        right:auto;
        bottom:auto;
        left:50%;
        top:310px;
        transform:translateX(-50%);
        width:100%;
        max-width:1500px;
    }
    .sticky-sidebar ul {
        width:50px;
        float:right;
    }
    .sticky-sidebar li {
        width: 50px;
        float:none;
        display: block;
        margin-bottom:5px;
        border:0;
    }
    .sticky-sidebar a {
        font-size:14px;
        position: absolute;
        right:0;
        top:0;
        text-align: center;
        padding-left:50px;
        white-space: nowrap;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.3);
    }
    .sticky-sidebar a .icon {
        top:14px;
        left:13px;
        font-size:24px;
    }
    .sticky-sidebar a .icon-besucher {
        left:18px;
    }
    .sticky-sidebar a span {
        position: relative;
        top:auto;
        left:auto;
        transform:translateY(0);
        display: none;
    }
    .sticky-sidebar li:hover a span {
        display: inline-block;
        padding:16px 10px 0 0;
    }
}