.filter-box {
    border-bottom: 1px solid #dfdfdf;
    padding: 5px 10px;
    background:transparent;
}
@media screen and (min-width: 1200px) {
    .filter-box {
        padding: 5px 20px;
    }
}
.filter-box__head {
    padding: 10px 0;
}

@media screen and (min-width: 768px) {
    .filter-box__title {
        font-size: 16px;
    }
    .form-group--filterbox  {
        padding:10px 0;
    }
    .form-group--filterbox>.form-control--input {
        padding-bottom: 20px;
        padding-left:10px;
        background:transparent;
    }
}

@media screen and (min-width: 1200px) {
    .filter-box__title {
        font-size: 18px;
    }
    .form-group--filterbox>.form-control--input {
        padding-left:20px;
    }
}

@media screen and (min-width: 1400px) {
    .filter-box__title {
        font-size: 20px;
        text-transform: uppercase;
    }
}

.filter-box__body {
    /*font-size: 15px;*/
}
.filter-box__icon {
    vertical-align: -.1em;
}
.filter-box__head .icon.filter-box__collapse-icon:before {
    position: relative;
    top:-4px;
    content: " ";
    display: block;
    width: 12px;
    height: 2px;
    background-color: #002F5D;
    color: var(--text-primary);
    font-size: 12px;
}
.filter-box__head.collapsed .icon.filter-box__collapse-icon:before {
    top:1px;
    background:transparent;
    width:auto;
    height:auto;
    content: var(--icon-open);
}