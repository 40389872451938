:root {
    --interactive-map-header-height: 62px;
}
.interactive-map {
    position: relative;
    overflow: hidden;
}

.interactive-map__map {
    transition: transform 120ms ease-out;
}
.interactive-map__overlay {
    transition: transform 120ms ease-out;
    z-index: 100;
}
@media screen and (min-width: 768px) {
    .interactive-map__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 280px;
    }
    .interactive-map.is-open .interactive-map__overlay {
        bottom: 0;
    }
}
@media screen and (min-width: 1200px) {
    .interactive-map__overlay {
        width: 340px;
    }
}

.interactive-map__overlay-head {
    height: var(--interactive-map-header-height);
    padding-left: 20px;
    position: relative;
    z-index: 1;
    background-color: #f8f8f8;
}
.interactive-map__overlay-title-icon {
    vertical-align: -.1em;
}
@media screen and (max-width: 767px) {
    .interactive-map__overlay-body {
        padding: 20px 10px;
    }
}
@media screen and (min-width: 768px) {
    .interactive-map__overlay-body {
        background: rgba(255,255,255,0.75);
        height: 80%;
        height: calc(100% - var(--interactive-map-header-height));
        padding: 18px 36px 18px 3px;
        /*transform: translateX(-100%);*/
        /*transform-origin: top center;*/
        /*transition: transform 240ms ease;*/
        overflow-y: auto;
        display: none;
    }
    .interactive-map__overlay-body::-webkit-scrollbar{
        -webkit-appearance: none;
        width: 5px;
    }
    .interactive-map__overlay-body::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #bebfc2;
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
    .interactive-map.is-open .interactive-map__overlay-body {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .interactive-map__overlay-body {
        display: none;
    }
    .interactive-map.is-open .interactive-map__overlay-body {
        display: block;
    }
}
.interactive-map__overlay-toggle {
    border-left: 2px solid #ffffff;
    padding: 0 17px;
    font-size: 10px;
}
.interactive-map__overlay-toggle-icon {
    transition: transform 120ms ease;
}
@media screen and (max-width: 767px) {
    .interactive-map__overlay-toggle-icon {
        transform: rotate(90deg);
    }
    .interactive-map.is-open .interactive-map__overlay-toggle-icon {
        transform: rotate(270deg);
    }
}
@media screen and (min-width: 768px) {
    .interactive-map.is-open .interactive-map__overlay-toggle-icon {
        transform: rotate(180deg);
    }
}

.interactive-map__overlay-head-additional {
    display: none;
}
@media screen and (min-width: 768px) {
    .interactive-map__overlay-head-additional {
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        margin: 20px;
        bottom: 0;
    }
    .interactive-map__overlay-head-additional-btn {
        position: relative;
        height: 30px;
        width: 30px;
        margin-bottom: 2px;
        color:#fff;
        background:rgba(0,0,0,0.4);
    }
    .interactive-map__overlay-head-additional-btn:hover,
    .interactive-map__overlay-head-additional-btn:focus{
        color:#fff;
        background:rgba(0,0,0,0.5);
    }
    .interactive-map__overlay-head-additional-btn .icon {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: center;
        font-size: 11px;
    }
    .interactive-map__overlay-head-additional-btn .icon-close {
        font-size: 2px;
    }
}



/*google map marker*/
.google-map__marker {
    font-size: 52px;
    color: var(--bg-primary);
    width: 35px;
    position: relative;
    top: 1px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.7);
}
.google-map__marker__icon {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    font-family: iconfont !important; /* bugfix */
    color: #fff;
    text-shadow: none;
}
.google-map__marker__icon.testundefined {
    font-family: var(--font-default);
    font-size: 16px;
}