/* pattern teaser */

.teaser-pattern .teaser__content {
    background:url(/static/build/img/pattern.jpg) left top repeat;
}

.teaser-pattern .teaser__content h3 {
    font-weight:300;
    width: 100%;
    font-family: var(--font-headline);
    color: var(--text-primary);
}

.teaser-pattern .icon {
    text-align: center;
    line-height:1;
    color:#606060;
    font-size: 50px;
}
.teaser-pattern .card {
    transform: rotate(-15deg);
    max-width: 75%;
    height: auto;
}


@media (max-width: 767px) {
    .teaser-pattern {
        /*margin: 0 10px;*/
        margin:0;
    }
    .row--has-pattern-teasers > .col {
        padding: 0;
    }
    .teaser-pattern {
        text-align: left;
    }
    .teaser-pattern .teaser__content {
        padding: 15px 10px;
        border-bottom: 1px solid var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .teaser-pattern .teaser__content h3 {
        margin: 0;
    }
    .teaser-pattern .icon {
        color: var(--text-primary);
        font-size: 20px;
    }
}



@media (min-width: 768px) {
    .row.row--has-pattern-teasers {
        margin: 0;
    }
    .teaser-pattern {
        padding-bottom: 120%;
        height: 0;
        position: relative;
        text-align: center;
        margin: 20px 10px 20px 10px;
    }
    .row--same-height__item.teaser-pattern {
        padding-bottom: 0%;
        height: auto;
        position: relative;
        text-align: center;
        margin: 30px 10px 0 10px;
    }
    .row.gutter-width-0 .teaser-pattern {
        margin: 20px 25px;
    }
    .teaser-pattern .teaser__content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .teaser-pattern .icon {
        font-size: 70px;
        text-align: center;
        line-height:1;
        color:#606060;
        font-size: 50px;
    }


}

@media (min-width: 992px) {

    .teaser-pattern .teaser__content h3 {
        margin-bottom: 10px;
        transition: all 0.4s ease-in-out;
    }
    .teaser-pattern:hover .teaser__content h3 {
        margin-bottom: -30px;
        transition: all 0.25s ease;
    }

}

@media (min-width: 1200px) {
    .teaser-pattern {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .teaser-pattern .icon {
        font-size: 90px;
    }

}