/*portal teasers*/
.portal-teaser {
    font-size:16px;
    margin:15px 0 50px 0;
    position: relative;
    transform:translateY(0);
    transition: all 0.25s ease;
}
.portal-teaser:hover {
    transform:translateY(-10px);
}
.portal-teaser__content {
    color: #424242;
}
.portal-teaser__icon {
    margin-bottom:10px;
    font-size:34px;
    color:var(--text-primary);
}
.portal-teaser__icon-small {
    margin-top:15px;
    font-size:30px;
    color:var(--text-primary);
}
.portal-teaser__headline {
    font-size:26px;
    color:var(--text-primary);
}
.portal-teaser__headline > a:hover,
.portal-teaser__headline > a:focus {
    color:var(--text-primary);
}
@media screen and (min-width: 768px) {
    .portal-teaser {
        margin:25px 0;
    }
    .portal-teaser__icon {
        margin-bottom:15px;
    }
    .portal-teaser__headline {
        font-size:28px;
    }
}

@media screen and (min-width: 1200px) {
    .portal-teaser {
        font-size:19px;
        margin:50px 0;
    }
    .portal-teaser__icon {
        font-size:50px;
    }
    .portal-teaser__headline {
        font-size:32px;
    }
}



/* xs-nav -- has-usp
------------------ */
@media screen and (max-width: 767px) {
    .portal-img {
        position: relative;
        margin: 0 -10px;
    }

    .slide-scroll-indicator {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(50%) rotate(90deg) !important;
        color: var(--text-primary);
    }
    .portal-top-img-text {
        position: absolute;
        bottom: 0;
        width: 70%;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-bottom: 10%;
        color:#fff;
        font-size: 16px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
    .portal-top-img-text h1 {
        font-size: 24px;
        margin-bottom:5px;
    }

    .nav--has-usp {
        text-align: center;
        padding: 35px 0 10px 0;
        margin: 0 auto;
        width: 85%;
    }
    .nav--has-usp ul li a {
        font-family: merriweather-bolditalic;
        font-size: 16px;
        display: block;
        padding: 12px 0;
    }
    .nav--has-usp ul li:not(:last-of-type) {
        border-bottom: 1px solid #bebebe;
    }


}





/* slide additional teasers */
body.is-portal .content-block--additional-teasers {
    background: #fff;
    z-index: 550;
    margin: 0;
    padding-bottom: 85px;
    padding-top: 35px;
    position: relative;
}
@media screen and (max-width: 767px) {
    body.is-portal .content-block--additional-teasers {
        padding-bottom: 10px;
    }
}