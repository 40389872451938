/* Slider */
.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}





/* dots */
.slick-slider .slick-dots {
    position: relative;
    white-space: nowrap;
    margin: 0 auto;
    padding:0;
    list-style-type: none;
    text-align: center;
    line-height: 1;
    background:transparent;
}
.slick-slider .slick-dots>li {
    position: relative;
    display: inline;
    background: transparent;
    margin: 0;
    padding: 0;
}
.slick-slider .slick-dots button {
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 0 8px;
    border-radius:0;
    border: 0;
    font-size: 1px;
    background:var(--text-light-grey);
    overflow: hidden;
    text-indent: -999em;
    transform: rotate(45deg);
}
.slick-slider .slick-dots>li.slick-active button {
    background: var(--bg-primary);
}
@media screen and (max-width: 767px) {
    .slick-slider .slick-dots {
        margin-top: 15px;
    }
    .slick-slider .slick-dots button {
        width: 18px;
        height: 18px;
    }
}


.slick-slide:focus {
    outline: none;
}

/* arrows */
.slick-thumb-arrow {
    cursor:pointer;
    color:var(--text-primary);
    position: absolute;
    left:0;
    width:45px;
    top: 50%;
    z-index: 120;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.slick-thumb-arrow--next {
    left:auto;
    right:0;
    text-align: right;
}

@media screen and (min-width: 768px) {
    .gallery-thumb .slick-thumb-arrow {
        left:-50px;
    }
    .gallery-thumb .slick-thumb-arrow--next {
        left:auto;
        right:-50px;
    }
}
.slick-thumb-arrow.slick-disabled {
    opacity: 0.35;
    transiton: opacity 200ms;
    cursor: not-allowed;
}


/* slick arrow -- bottom (xs version without dots) */
.slick-thumb-arrow.slick-thumb-arrow--bottom {
    width:45px;
    top: auto;
    bottom: 0;
    transform: translateY(100%);
    padding: 7px 5px;
}

/*  slide-counter */
.slide-counter {
    display: none;
}
@media screen and (max-width: 767px) {

    .gallery--has-xs-counter .slide-counter {
        display: block;
        color: var(--text-primary);
        font-family: var(--font-bold);
        text-align: center;
        padding: 5px 50px;
    }

}


/* editmode slider */
.slider--editmode .slick-thumb-arrow {
    top:auto;
    bottom: -30px;
    transform: translateY(0);
}


/* service slider xs */
.slider-xs {
    padding-bottom:10px;
}
.slider-xs .slick-dots {
    margin-bottom:15px;
}