#footer-social,
#footer-bottom {
    visibility: visible;
}

#site-footer {
    display: block;
    background: #fff;
    position: relative;
}


/* footer-bottom
 --------------- */
#footer-bottom {
    font-size: 14px;
    padding: 10px 0 55px 0;
}
@media screen and (min-width: 768px) {
    #footer-bottom {
        padding: 15px 0;
    }
}
@media screen and (min-width: 1200px) {
    #footer-bottom {
        padding: 20px 0;
    }
}

/*footer quicklinks*/
.list-quicklinks > li {
    padding: 0;
}
.list-quicklinks > li > a {
    padding: 10px;
}
.list-quicklinks > li + li:before {
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: var(--text-secondary);
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: -2px;
}
@media screen and (max-width: 767px) {
    .list-quicklinks {
        margin: 10px 0;
        color: var(--text-primary);
    }
}
@media screen and (min-width: 768px) {
    .list-quicklinks {
        margin: 0;
    }
}

/* footer share
----------------  */
@media screen and (min-width: 768px) {
    .bottom__elements {
        display: flex;
    }
}

/* elements-logo
----------------  */
.link__elements > span {
    color: #002F5D;
}
@media screen and (min-width: 768px) {
    .link__elements {
        margin-left: 25px;
        display: flex;
    }
    .link__elements > span {
        font-size: 20px;
        margin-left:10px;
    }
}
@media screen and (max-width: 767px) {
    .link__elements > span {
        display: block;
        font-size: 25px;
        margin-top: 5px;
    }
    #site-footer a.btn[href^="tel:"] {
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}


/* footer-bottom
----------------  */
@media screen and (max-width: 767px) {
    #footer-bottom {
        text-align: center;
    }
}
#footer-bottom > .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#footer-bottom > .container:before,
#footer-bottom > .container:after {
    display: none;
}


@media screen and (min-width: 768px) {
    #footer-bottom > .container {
        flex-direction: row;
    }
}

/* elements-logo
----------------  */
@media screen and (max-width: 767px) {
    .footer-bottom__linkedin {
        margin: 20px 0;
    }
}
@media screen and (min-width: 768px) {
    .footer-bottom__linkedin {
        display: flex;
        align-items: center;
    }
    .link__linkedin {
        color: #002F5D;
        display: flex;
    }
}
