.pagination > li > a,
.pagination > li > span {
    color: #000000;
}

.pagination > li.li-has-arrow > a {
   margin: 0;
}
.pagination > li.li-has-arrow:not(.disabled) > a {
    color: var(--text-primary);
}
.pagination > li.li-has-arrow > a:hover {
    color: var(--text-primary);
    background-color: transparent;
}

@media screen and (max-width: 767px) {
    .pagination {
         display: flex;
         align-items: center;
        justify-content: center;
    }
    .pagination > li:not(:first-of-type):not(:last-of-type).disabled > a {
        padding: 0;
    }
    .pagination > li > a,
    .pagination > li > span {
        padding: 11px 14px;
    }
    .row.paging-row > .col {
        text-align: center;
    }
    .row.paging-row > .col + .col {
        margin-top: 15px;
    }
}
@media screen and (min-width: 768px) {
    .paging-row .has-paging--list {
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }
    .paging-row {
        display: flex;
        align-items: center;
    }
}