.quick-filter {
    display: block;
    background: none;
    border: none;
    text-align: left;
    font-size: 13px;
    padding: 4px 10px 4px 2px;
    overflow: hidden;
}
.quick-filter__icon {
    position: relative;
    top:-1px;
    font-size: 8px;
}