@media screen and (min-width: 768px) {

    #header-main > .container--is-sticky {
        width: 100%;
        max-width: 1500px;
        background: #fff;
    }
    #header-main > .container--is-sticky.is-transitioning,
    #header-main > .container--is-sticky.is-affix {
        height: 80px;
        z-index: 5000;
        background: #fff;
        box-shadow: 0px 3px 3px -3px rgba(0,0,0,.3);
    }

    #header-main > .container--is-sticky.is-transitioning {
        position: absolute;
    }
    #header-main > .container--is-sticky.is-affix {
        position: fixed;
    }


    .is-transitioning #nav-main .nav,
    .is-affix #nav-main .nav {
         height: 80px;
         align-items: center;
    }
    .is-transitioning #nav-main .nav > li,
    .is-affix #nav-main .nav > li {
        border-top: none;
    }
    .is-transitioning #nav-main .nav > li > a,
    .is-affix #nav-main .nav > li > a {
        height: 80px;
        line-height: 80px;
        padding-top: 0;
        padding-bottom: 0;
    }

    /* logo */
    .is-transitioning #nav-main .nav > li.has-logo,
    .is-affix #nav-main .nav > li.has-logo {
        height: 50px;
        top: 0;
    }



}

@media screen and (min-width: 1500px) {
    #header-main > .container--is-sticky.is-transitioning,
    #header-main > .container--is-sticky.is-affix {
        height: 80px;
    }
}


/* FIXED HEADER --> LOGO
 -----------------------  */
@media screen and (min-width: 768px) {

    .container--is-sticky.is-transitioning #nav-main ul > li.has-logo > a,
    .container--is-sticky.is-affix #nav-main  ul > li.has-logo > a {
        background-image: url(/static/img/logo/logo-sticky-de.svg);
        height: 50px;
    }

}