.lang-flag {
    background-image: url(/static/build/img/spritesheet_lang.png);
    background-repeat: no-repeat;
    display: inline-block;
}

.lang-flag--zh {
    width: 20px;
    height: 12px;
    background-position: -5px -5px;
}

.lang-flag--de{
    width: 20px;
    height: 12px;
    background-position: -35px -5px;
}

.lang-flag--en {
    width: 20px;
    height: 12px;
    background-position: -5px -27px;
}

.lang-flag--fr {
    width: 20px;
    height: 12px;
    background-position: -35px -27px;
}

.lang-flag--it{
    width: 20px;
    height: 12px;
    background-position: -5px -49px;
}

.lang-flag--ja {
    width: 20px;
    height: 12px;
    background-position: -35px -49px;
}

.lang-flag--pl {
    width: 20px;
    height: 12px;
    background-position: -65px -5px;
}

.lang-flag--ru {
    width: 20px;
    height: 12px;
    background-position: -65px -27px;
}

.lang-flag--es {
    width: 20px;
    height: 12px;
    background-position: -65px -49px;
}

.lang-flag--cs {
    width: 20px;
    height: 12px;
    background-position: -5px -71px;
}

.lang-flag--hu {
    width: 20px;
    height: 12px;
    background-position: -35px -71px;
}
