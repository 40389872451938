:root {
    --categories-circle-diameter: 142px;
}

@media screen and (max-width: 767px) {
    #modal--blog-nav .modal-dialog {
        margin-top: 30px;
    }

    #modal--blog-nav .blog-slide {
        padding-bottom: 0;
    }
    #modal--blog-nav .blog-categories {
        background: transparent;
    }
}


.blog-slide.blog-categories {
    background: var(--bg-primary);
    padding-top: 40px;
    padding-bottom: 65px;
}

#modal--blog-nav {
    background-image: linear-gradient(180deg, #002F5D 0%, #000c18 100%);
}

#modal--blog-nav .blog-categories {
    background: transparent;
}

#modal--blog-nav .close {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 5px;
    color: white;
    z-index: 1;
}

#modal--blog-nav .close:hover,
#modal--blog-nav .close:focus {
    color: white;
}


.category-list {
    position: relative;
    display: inline-block;

    padding-left: 0;
    list-style: none;
}



.category-list__item{
    position: relative;
    display: inline-block;
    margin-left: 44px;
    margin-right: 44px;
    transform: translateZ(0);
}

.category-list__item:first-of-type {
    margin-left: 0;
}

.category-list__item:last-of-type {
    margin-right: 0;
}

.category-list__link {
    display: block;
    position: relative;
    width: var(--categories-circle-diameter);
    height: var(--categories-circle-diameter);
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.5);
    text-align: center;
    transition: transform .35s ease;
}

.category-list__link:hover {
    transform: translateY(-10px);
}

@media screen and (max-width: 767px) {

    /* Show 2 items per row*/
    .category-list__item {
        width: 50%;
        float: left;
        margin: 0 0 20px;
    }

    .category-list__link {
        margin: 0 auto;
        width: 130px;
        height: 130px;
    }

}

@media screen and (min-width: 768px) {
    .category-list--lines {
        padding-top: 50px;
    }

    .category-list--lines:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(0.5 * var(--categories-circle-diameter));
        right: calc(0.5 * var(--categories-circle-diameter));
        height: 0;
        border-bottom: 1px solid rgba(255,255,255,0.5);
    }


    .category-list--lines .category-list__item:before{
        content: '';
        position: absolute;
        left: 50%;
        height: 29px;
        top: -50px;
        width: 0;
        border-left: 1px solid rgba(255,255,255,0.5);
    }

}


.category-list__item__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    color: #ffffff;
    font-size: 20px;
}

.category-list__item__content .icon {
    font-size: 30px;
}