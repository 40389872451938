.inline-nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 53px;
    font-size: 18px;
    line-height: calc(26 / 18);
    overflow: hidden;
}
.inline-nav__title {
    position: relative;
    font-size: 1em;
    color: inherit;
    margin: 0;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    text-transform: none;
    padding: 12px 45px 12px 0;
    margin-right: 27px;
}
.inline-nav__title:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 182%;
    background: #fff;
    transform: rotate(45deg) translateY(-13%) translateX(-11px);
    transform-origin: center;
    opacity: .6;
}
.inline-nav__link {
    display: inline-block;
    padding: 12px 20px;
    opacity: 0.6;
}
.inline-nav__link:hover,
.inline-nav__link:focus {
    color: inherit;
    opacity: 1;
}