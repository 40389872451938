.img-teaser {
    position: relative;
    overflow: hidden;
    /*-webkit-transform: translateZ(0);*/
    /*-webkit-backface-visibility: hidden;*/
}


.img-teaser:hover .img-teaser__hover-overlay {
    opacity: .8;
}

.img-teaser__hover-overlay {
    position: absolute;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    /*width: 100%;*/
    /*height: 100%;*/
    opacity: 0.8;
    background-color: #002F5D;
    transition: opacity 120ms;
    /*transform: translateZ(0);*/
    /*-webkit-backface-visibility: hidden;*/
}

.img-teaser .teaser-img:after,
.img-teaser .teaser-img:before{
    content:' ';
    position: absolute;
    left:20px;
    right:20px;
    top:20px;
    bottom:20px;
    opacity:0;
    z-index:35;
}

@media screen and (max-width: 767px) {
    .img-teaser .teaser-img:after,
    .img-teaser .teaser-img:before {
        left: 10px;
        right: 10px;
        bottom: 10px;
        top: 10px;

    }
}

.img-teaser .teaser-img:before{
    border-top: 1px dotted #fff;
    border-bottom: 1px dotted #fff;
    transform: scale(0,1);
    transform-origin: 0 0;
}
.img-teaser .teaser-img:after{
    border-right: 1px dotted #fff;
    border-left: 1px dotted #fff;
    transform: scale(1,0);
    transform-origin: 100% 0;
}
.img-teaser:hover .teaser-img:after,
.img-teaser:hover .teaser-img:before,
.img-teaser:hover .img-teaser__content {
    opacity:1;
    transform: scale(1);
}
.img-teaser .teaser-img, .img-teaser .teaser-img:before, .img-teaser .teaser-img:after {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.img-teaser__content {
    position: absolute;
    top: 40%;
    left: 20px;
    right: 20px;
    text-align: center;
    color: white;
    font-family: var(--font-headline);
    font-size: 16px;
    transition: opacity 0.35s;
}

@media screen and (max-width: 767px) {
    .img-teaser__content {
        top: 10%;
    }

}


/* only hide on desktop, so tab and phone can always see the title*/
html.desktop .img-teaser__content,
html.desktop .img-teaser__hover-overlay {
    opacity: 0;
}



.img-teaser__icon {
    font-size: 25px;
    margin-top: 10%;
}

.img-teaser__category {
    font-family: var(--font-default);
    font-size: 15px;
    margin-bottom: 8px;
}