.form-container {
    position: relative;
    margin-bottom:25px;
}

@media screen and (min-width: 1200px) {
    .form-container {
        padding:50px 200px 80px 200px;
        border:2px solid #efefef;
        margin-bottom:50px;
    }
}
@media screen and (min-width: 768px) {
    .form-container.form-container--small {
        padding: 35px;
        margin-bottom: 25px;
    }
    .submit-container {
        position: absolute;
        top:100%;
        left:0;
        right:0;
    }
    .submit-container button {
        margin-top:1px;
        position: relative;
        top: 50%;
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.form-control:focus::-moz-placeholder {
    color: transparent;
}
.form-control:focus:-ms-input-placeholder {
    color: transparent;
}
.form-control:focus::-webkit-input-placeholder {
    color: transparent;
}


.form-section {
    padding:15px 0;
    position: relative;
}



/* CHECKBOXES - RADIOS
 ------------------------- */
.checkbox-wrap input[type="checkbox"] {
    opacity:0;
    margin: 0;
}
.checkbox-wrap label {
    padding-left: 30px;
    user-select: none;
}

.checkbox-wrap label:before {
    content: '';
    position: absolute;
    left:0;
    top: 0;
    width: 19px;
    height: 19px;
    background: #fff;
    border: 1px solid #979797;
    transition: background 0.15s;
}
.checkbox-wrap input[type="checkbox"]:checked + label:before {
    background: #1B8221;
    border-color:#1B8221;
}
.checkbox-wrap input[type=checkbox]:checked+label:after{
    content: var(--icon-check);
    font-family: "iconfont";
    position: absolute;
    left: 4px;
    top: -1px;
    width: 19px;
    height: 19px;
    font-size: 16px;
    color: #fff;
}
.checkbox-wrap input[type="checkbox"]:checked + label {
    color: #1B8221;
}
.checkbox-wrap input[type="checkbox"]:focus + label {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

/* radio */
.radio-wrap {
    position: relative;
}
.radio-wrap input[type="radio"] {
    opacity:0;
    margin: 0;
}
.radio-wrap label {
    padding-left: 30px;
    position: relative;
}
.radio-wrap label:before {
    content: '';
    position: absolute;
    left:0;
    top: 1px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #979797;
}
.radio-wrap input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    left:5px;
    top:6px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #424242;
}
@media screen and (max-width: 767px) {
    .radio-wrap + .radio-wrap {
        margin-top: 10px;
    }
}
.radio-wrap input[type="radio"]:focus+label {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

@media screen and (min-width: 768px) {
    .form-inline .radio-wrap + .radio-wrap {
        margin-left: 15px;
    }
}







/* LABELS
 -------------------- */
.form-group--has-label {
    position: relative;
    margin: 20px 0;
}
.form-group--has-label + .form-group--has-label {
    margin-top: 30px;
}
.form-control--has-datepicker {
    cursor: pointer;
}
.form-control--input {
    position: relative;
    display: block;
    padding: 5px 10px 5px 0;
    border: none;
    border-radius: 0;
    height: 40px;
    background: #fff;
    color: #000;
    border-bottom: 1px solid #dfdfdf;
}
.form-control--input__line {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    display: block;
    height: 1px;
    border-bottom: 1px solid #000;
    z-index: 10;
    transition: width 0.25s ease;
}
.form-control--input__label {
    /*display: inline-block;*/
    /*float: right;*/
    position: absolute;
    font-weight: normal;
    font-size: 16px;
    color:#000;
    text-align: left;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    padding:0 5px 0 0;
    vertical-align: middle;
    display: block;
    transition: all 0.25s ease;
    /*background:#fff;*/
}
.number-label {
    margin-top:8px;
    color: #000;
}

@media screen and (min-width: 768px) {
    textarea.form-control + .form-control--input__label {
        top: 20px;
    }
    .form-control--input__label--relative {
        position: relative;
        top:12px;
    }
    .form-control--input__label {
        font-size: 20px;
    }
    .form-control--input {
        height: 50px;
    }
    .form-group--has-captcha {
        margin-top: 30px;
    }
}

/* focus */
@media screen and (min-width: 768px) {
    .form-control--input.input-not-empty + .form-control--input__label,
    .form-control--input.input-not-empty-date ~ .form-control--input__label,
    .form-control--input:focus + .form-control--input__label {
        font-size:12px;
        background:transparent;
        top: 0;
        transform: translateY(-100%);
    }
    .form-control--input:focus ~ .form-control--input__line {
        width: 100%;
    }

    .number-label {
        margin-top:35px;
        font-size: 20px;
    }

}


@media screen and (max-width: 767px) {
    .form-group--has-label {
        position: relative;
        margin:0;
        padding:20px 0;
    }
    .form-control--input__label {
        font-size:14px;
        top: 5px;
    }
    .form-control--input {
        border: 1px solid #dfdfdf;
        padding: 5px 10px;
    }
}

/* inline form */
.form-inline label {
    margin-right:40px;
    font-family:var(--font-default);
    font-weight: 400;
}
.form-inline .form-control {
    padding-top:0;
}

/* validation */
.parsley-errors-list {
    display: none;
}
.has-error .form-control--input__label {
    color:#002F5D;
}
.has-error .form-control,
.checkbox-wrap.has-error label:before{
    border-color: #002F5D;
}
.checkbox-wrap.has-error {
    color:#002F5D;
    /*border-bottom:1px solid #9e231e;*/
}
.has-error .form-control--input__line {
    border-bottom: 1px solid #002F5D;
}

.has-success .form-control--input__label {
    color:#378825;
}
.has-success .form-control {
    border-color: #378825;
}
.has-success .form-control--input__line {
    border-bottom: 1px solid #378825;
}


/* SINGLE DATEPICKER */
.form-group--has-datepicker {
    display: flex;
    align-items: center;
}
.form-group--has-datepicker .form-control {
    background-color: transparent;
}
.form-group--has-datepicker > .icon {
    width: 20px;
    right: 20px;
    position: relative;
    pointer-events: none;
}
