
@media screen and (max-width: 767px) {
    .content-block--with-filters {
        padding-top: 25px;
    }
}

@media screen and (min-width: 768px) {
    .content-block--with-filters {
        position: relative;
        display: block;
    }
    .content-block--with-filters .filterbox__list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content-block--with-filters .filterbox__list > li {
         border-bottom: none;
    }
    .content-block--with-filters .filterbox__list > li.li--has-border-bottom {
        border-bottom: 1px solid #dfdfdf;
    }
}


.event-date-headline {
    margin:20px 0 0 0;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #dfdfdf;
}

@media screen and (min-width: 768px) {
    .event-date-headline {
        margin:40px 0 0 0;
        padding: 40px 0;
    }
}

/* events header filter -- has-top-events
 ------------------------------------------ */
.content-block--with-filters .filterbox__list > li.li--has-topevents-checkbox {
    background: #f8f8f8;
    padding-left: 25px;
    padding-right: 25px;
}
.content-block--with-filters .filterbox__list > li .topevent-star {
    position: absolute;
    left: -10px;
    top: -10px;
    font-size: 25px;
    color: var(--text-primary);
}

@media screen and (max-width: 767px) {
    .content-block--with-filters .filterbox__list > li.li--has-topevents-checkbox {
        width: auto;
        margin: 10px auto;
        border: none;
    }
}








/* detail events list */
.detail-events-list {
    position: relative;
    padding:20px 90px 20px 0;
    border-bottom:1px solid #dfdfdf;
}
.detail-events-list.detail-events-list--noborder {
    border-bottom:0;
}
.detail-events-list h3 {
    font-weight:300;
    line-height: 1;
    margin:0;
    color: #002F5D;
    font-family: var(--font-headline);
}
.detail-events-list h3 small{
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    color: #424242;
    white-space: nowrap;
    font-family: var(--font-default);
}
.detail-events-list a.link-arrow{
    color: var(--text-primary);
    position: absolute;
    top: 50%;
    margin-top:-11px;
    transform: translateZ(0);
    right:0;
}
@media screen and (max-width: 767px) {
    .detail-events-list h3 small{
        padding-top:5px;
        display: block;
    }
}
@media screen and (min-width: 768px) {
    .detail-events-list {
        padding:40px 120px 40px 0;
    }
    .detail-events-list h3 small{
        font-size: 15px;
        margin-left:20px;
    }
}

.event-header .filterbox + .btn{
    margin: 0;
}