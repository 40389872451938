/* DETAIL SITE FOR EVENTS AND A-Z
 ::::::::::::::::::::::::::::::::  */

/* HEADER - DETAIL - SITE
 ------------------------ */

/* map */
.static-map {
    position: relative;
}
.detail-site-header {
    position: relative;
}
.detail-site-header .col .embed-responsive {
    padding-top:50%;
}
.detail-site-header .no-poi-pos .icon:before {
    content:'' !important; /* hide markers if no lat/lng */
}
.static-map__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--text-primary);
    font-size: 30px;
    display: block;
    z-index: 200;
    opacity: 1;
}
.static-map__icon:before {
    transform: translateY(-100%) translateX(-50%);
    display: block;
}

@media screen and (min-width: 768px) {
    .static-map__icon {
        opacity: 0;
        animation: staticMapIconAnim 1.35s ease 1s forwards;
    }
    .detail-site-header {
        margin:-80px 0 0 0;
    }
    .detail-site-header .col .embed-responsive {
        padding-top: 64%;
    }
    .detail-site-header .col.col-sm-12 .embed-responsive {
        padding-top: 32%;
    }
    .static-map__icon {
        font-size: 40px;
    }
}
.xs-map-toggler {
    display: none;
}
@media screen and (max-width: 767px) {
    .detail-site-header {
        overflow:hidden;
    }
    .detail-site-header .col .embed-responsive.embed-responsive--has-g-map {
        padding-top: 75%;
        z-index: 100;
    }
    .col--has-google-map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        visibility: hidden;
    }
    .col--has-google-map.is-not-hidden {
        visibility: visible;
    }
    .col-sm-12.col--has-google-map.is-not-hidden {
          position: relative;
          visibility: visible;
    }
    .xs-map-toggler {
        display: block;
        position: absolute;
        top: 50%;
        right: -30px;
        margin-top:-30px;
        background: var(--text-primary);
        color: #fff;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        font-size: 23px;
        padding-left: 9px;
        z-index:999;
    }
    .xs-map-toggler > span {
        line-height: 60px;
    }
    .xs-map-toggler.is-open > span {
        line-height: 50px;
    }
    .xs-map-toggler.is-open > .icon:before {
        content: var(--icon-x);
        font-size: 18px;
    }
}


/* DETAIL - SITE - MAIN
 ------------------------ */

.detail-site-main__item {
    display: flex;
}
.detail-site-main__item .col--has-skew-icon {
    overflow: hidden;
    margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
    .detail-site-main__item .col--has-skew-icon {
        padding-bottom: 50px;
    }
}


.col--has-skew-icon .skew-icon {
    position: absolute;
    left: 12px;
    top: 13px;
}
.detail-site-main__item .skew-icon .skew-icon--line {
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: 100%;
    margin-top: 20px;
    width: 2px;
    border: 1px solid #efefef;
    height: 2000px;
    display: block;
}

.detail-site-main__item .wysiwyg {
    padding: 15px 0;
}
.detail-site-main__item .accordion .wysiwyg {
    padding:0;
}
.detail-site-main__item .wysiwyg.lead {
    margin: 0;
}
.detail-site-main__item .wysiwyg--has-h1 h1,
.detail-site-main__item .wysiwyg--has-h1 .h1 {
    margin-bottom: 0;
}
.detail-site-main__item .wysiwyg--has-h1 .hr--has-svg{
    margin-top:20px;
    margin-bottom:0;
}

.detail-site-main__item .wysiwyg.wysiwyg--has-hl,
.detail-site-main__item .wysiwyg--has-h1 + .wysiwyg.lead{
    padding-top:0;
}
.detail-site-main__item .wysiwyg--has-h1 + .wysiwyg{
    padding-top:10px;
}


.detail-site-main__item .link-arrow + .link-arrow,
.detail-site-main__item .has-link-list > ul > li + li {
    margin-top: 5px;
}
@media screen and (min-width: 768px) {
    .detail-site-main__item .wysiwyg--has-h1 .hr--has-svg{
        margin-top:40px;
    }
    .detail-site-main__item .has-link-list--higher .link-arrow+.link-arrow,
    .detail-site-main__item .has-link-list--higher>ul>li+li{
        margin-top: 25px;
    }
    .detail-site-main__item .has-link-list--higher .link-arrow,
    .detail-site-main__item .has-link-list--higher>ul>li{
        line-height:1.75;
    }
    .detail-site-main__item .has-link-list--higher .link-arrow:before {
        top:4px;
    }
}

.detail-site-main__item hr {
    margin: 5px 0;
}

.detail-site-main__item  .accordion hr {
    margin: 15px 0;
}

@media screen and (min-width: 768px) {

    .detail-site-main__item .wysiwyg {
        padding: 30px 0;
    }
    .detail-site-main__item .wysiwyg + .wysiwyg {
        padding-top: 0;
    }
    .detail-site-main__item .wysiwyg.readmore {
        padding-bottom: 0;
    }
    .detail-site-main__item .wysiwyg.wysiwyg--has-headline {
        padding: 20px 0;
    }

    .col--has-skew-icon .skew-icon {
        position: absolute;
        left: 30px;
        top: 20px;
    }
    .col--has-skew-icon .skew-icon--lg {
        top:25px;
        left:22px;
    }
    .detail-site-main__item .skew-icon .skew-icon--line {
        margin-top: 30px;
    }

    .detail-site-main__item  .accordion hr {
        margin: 25px 0;
    }

}

@media screen and (min-width: 1200px) {
    .detail-site-main__item  .accordion hr {
        margin: 35px 0;
    }
    .col--narrow {
        padding-right:150px;
    }
}


.detail-site-main__item .wysiwyg.wysiwyg--no-padding {
    padding: 0;
}