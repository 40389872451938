.modal-content [data-dismiss="modal"] {
    cursor: pointer;
    padding: 3px;
    display:block;
}
@media screen and (min-width: 768px) {
    .modal {
        text-align: center;
        padding: 0!important;
    }

    .modal:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }
}
@media screen and (max-width: 767px) {

    .modal-dialog {
        margin-top: 80px;
    }
    .modal-content [data-dismiss="modal"] {
        display: block;
        padding:3px 3px 5px 20px;
    }

}

.modal-full-width {
    width: auto;
}

.close-modal-abs {
    position:absolute;
    right:10px;
    top:10px;
    z-index:10;
}