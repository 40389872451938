.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    border:0;
}
@media screen and (max-width: 767px) {
    .wysiwyg .table-responsive table {
        margin-bottom: 0;
    }
}
@media screen and (min-width: 768px) {
    .wysiwyg table {
        margin-bottom: 45px;
    }
}
.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th,
.wysiwyg table > tfoot > tr > th,
.wysiwyg table > thead > tr > td,
.wysiwyg table > tbody > tr > td,
.wysiwyg table > tfoot > tr > td {
    padding:15px 20px;
    background: #f0f0f0;
    border:2px solid #fff;
}
.wysiwyg table > tbody > tr:nth-child(odd) > td,
.wysiwyg table > tbody > tr:nth-child(odd) > th{
    background: #f0f0f0;
}


.wysiwyg table > thead > tr > th,
.wysiwyg table > tbody > tr > th {
    font-family: var(--font-default);
    background:var(--bg-primary) !important;
    font-weight: normal;
    color:#fff;
}


.wysiwyg table th.valign-top,
.wysiwyg table td.valign-top {
    vertical-align:top;
}


/* table bordered
.wysiwyg table.table-bordered {
    border:0;
}
.wysiwyg table.table-bordered > tbody > tr:nth-child(odd) > td,
.wysiwyg table.table-bordered > tbody > tr:nth-child(odd) > th{
    background: transparent;
}
.wysiwyg table.table-bordered > thead > tr > th {
    background: transparent;
    color:inherit;
    border-bottom:1px solid #606060 !important;
}
.wysiwyg table.table-bordered > thead > tr > th,
.wysiwyg table.table-bordered > tbody > tr > th,
.wysiwyg table.table-bordered > tfoot > tr > th,
.wysiwyg table.table-bordered > thead > tr > td,
.wysiwyg table.table-bordered > tbody > tr > td,
.wysiwyg table.table-bordered > tfoot > tr > td {
    border-left:0;
    border-right-color:#606060;
}
.wysiwyg table.table-bordered > thead > tr:first-child > th,
.wysiwyg table.table-bordered > tbody > tr:first-child > th,
.wysiwyg table.table-bordered > tfoot > tr:first-child > th,
.wysiwyg table.table-bordered > thead > tr:first-child > td,
.wysiwyg table.table-bordered > tbody > tr:first-child > td,
.wysiwyg table.table-bordered > tfoot > tr:first-child > td {
    border-top: 0;
}
.wysiwyg table.table-bordered > thead > tr:last-child > th,
.wysiwyg table.table-bordered > tbody > tr:last-child > th,
.wysiwyg table.table-bordered > tfoot > tr:last-child > th,
.wysiwyg table.table-bordered > thead > tr:last-child > td,
.wysiwyg table.table-bordered > tbody > tr:last-child > td,
.wysiwyg table.table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
}
.table-bordered>thead>tr>th:first-child,
.table-bordered>tbody>tr>th:first-child,
.table-bordered>tfoot>tr>th:first-child,
.table-bordered>thead>tr>td:first-child,
.table-bordered>tbody>tr>td:first-child,
.table-bordered>tfoot>tr>td:first-child {
    border-left:0;
    border-right-color:#606060;
}
.table-bordered>thead>tr>th:last-child,
.table-bordered>tbody>tr>th:last-child,
.table-bordered>tfoot>tr>th:last-child,
.table-bordered>thead>tr>td:last-child,
.table-bordered>tbody>tr>td:last-child,
.table-bordered>tfoot>tr>td:last-child {
    border-right:0;
}
*/