@media screen and (min-width: 768px) {
    .teaser {
        margin-top: 22px;
    }
}
@media screen and (min-width: 1200px) {
    .teaser {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

/* teaser */
.teaser {
    position: relative;
    padding:0 7px;
    transition: all 0.25s ease;
}
.teaser .teaser-img {
    overflow:hidden;
    position: relative;
}

.teaser .embed-responsive {
    background-size:cover;
    background:url(/static/img/placeholder.png) 50% 50% no-repeat;
}
@media screen and (max-width: 767px) {
    .teaser .embed-responsive {
        background-size:280px 140px;
    }
}
.teaser .map-alphabet {
    display: block;
    width:22px;
    height:22px;
    padding-top:3px;
    text-align:center;
    color:#fff;
    font-size:12px;
    position: absolute;
    top:12px;
    right:12px;
    z-index:50;
    border-radius:50%;
}
.teaser .map-alphabet:after {
    display: block;
    width:22px;
    height:22px;
    position: absolute;
    z-index:-1;
    top:0px;
    right:0px;
    font-size:22px;
    color:var(--bg-primary);
    font-family: iconfont;
    content: var(--icon-map_marker);
}

.teaser .teaser-distance {
    display: inline-block;
    padding:3px 8px;
    text-align:center;
    color:#fff;
    font-size:12px;
    position: absolute;
    top:12px;
    left:12px;
    z-index:50;
    background:var(--bg-primary);
}
.teaser .topline {
    font-size:12px;
}

.teaser .teaser__content {
    position: relative;
    padding: 20px 0px 10px 0px;
    text-align: center;
    background:#fff;
    transition: all 0.15s linear;
}

@media screen and (min-width: 768px) {
    .teaser .topline {
        font-size:14px;
    }
    .teaser .teaser__content {
        padding: 25px 15px 15px 15px;
    }
}
.teaser .category-icon{
    display: block;
    width:30px;
    height: 30px;
    line-height:30px;
    text-align:center;
    position: absolute;
    top:0;
    left:50%;
    margin: -15px 0 0 -15px;
    background:#fff;
    border-radius: 15px 15px 0 0;
    color: var(--text-primary);
    font-size: 16px;
    transform: rotate(0deg) translateZ(0);
    transition: all 0.1s;
    z-index:45;
}
.teaser .category-icon:before {
    display: inline-block;
    transform: rotate(0deg);
}

.teaser .category-icon.category-icon--big {
    width:50px;
    height: 25px;
    line-height: 50px;
    margin: -24px 0 0 -25px;
    border-radius: 25px 25px 0 0;
}

@media screen and (min-width: 768px) {
    .teaser .category-icon{
        width:50px;
        height: 25px;
        line-height: 50px;
        margin: -25px 0 0 -25px;
        font-size: 24px;
        border-radius: 25px 25px 0 0;
    }
    .teaser:not(.teaser--event-hero) .category-icon {
        margin-top:-24px;
    }

    .teaser .category-icon.category-icon--big{
        width: 80px;
        height: 40px;
        line-height: 80px;
        margin: -40px 0 0 -40px;
        font-size: 30px;
        border-radius: 40px 40px 0 0;
    }

    .teaser:not(.teaser--event-hero) .category-icon.category-icon--big {
        margin-top:-39px;
    }

    .category-icon.category-icon--big ~ h3,
    .category-icon.category-icon--big ~ .teaser__title {
        z-index: 50;
        position: relative;
    }
}

.teaser__text {
    font-size:15px;
    line-height: calc(22/15);
}
.teaser__text > p {
    margin: 0;
}
.teaser__text ul {
    list-style-type: circle;
    display: inline-block;
}
.teaser__text ul,
.teaser__text ul li {
    margin:0;
    padding:0;
}
@media screen and (max-width: 767px) {
    .teaser__text {
        font-size:14px;
    }
    .teaser__text ul {
        list-style-type: none;
    }
}



/* teaser headline
---------------------- */

.teaser h3,
.teaser .teaser__title {
    font-family: var(--font-headline);
    color: var(--text-primary);
    width: 100%;
}
.teaser h3 a,
.teaser .teaser__title a {
    text-decoration: none;
}
.teaser h3.overlay-headline,
.teaser .teaser__title.overlay-headline {
    padding: 0 10px;
    text-align:center;
    position: absolute;
    top: 40px;
    left:0;
    right:0;
    color:#fff;
    z-index:10;
}
@media screen and (max-width: 767px) {
    .teaser h3,
    .teaser .teaser__title {
        line-height: 1.25;
        font-size: 13px;
    }
}
@media screen and (min-width: 1200px) {
    .teaser h3,
    .teaser .teaser__title {
        line-height: 1.4;
    }
}

.teaser footer {
    padding: 5px 5px 0 5px;
    margin-top: 5px;
    border-top: 1px solid #dfdfdf;
    font-size: 14px;
}
.teaser--event:not(.teaser--event-hero) footer {
    width: 100%;
}
.teaser .price {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 6px 8px;
    line-height: 1;
    color:#fff;
    font-family: var(--font-headline);
    font-size: 13px;
    background-color: var(--bg-primary);
    border-top-left-radius: 27px;
    border-bottom-left-radius: 27px;
    transition: all 0.25s ease;
    z-index:50;
}
.teaser .price .currency {
    font-size: 14px;
}
.teaser .price .currency-info {
    font-size: 12px;
}
.teaser .price .comma {
    font-size: 10px;
    margin-left: -2px;
}

@media screen and (min-width: 768px) {

    .teaser .price {
        top: 18px;
        padding: 10px  15px;
        font-size: 20px;
    }
    .teaser .price .currency-info {
        font-size: 14px;
    }
    .teaser .price .comma {
        font-size: 14px;
        margin-left:-8px;
    }
}
@media screen and (max-width: 767px) {
    .teaser .teaser__mobile-button {
        text-align:center;
        padding:8px 0;
    }
}


/* farbvariante rot */
.teaser .teaser__content.bg-primary {
    background:var(--bg-primary);
}
.teaser .bg-primary h3 a,
.teaser .bg-primary h3 {
    color: #fff;
}
.teaser .category-icon.bg-primary {
    background: var(--bg-primary);
    color: #fff;
}
.teaser .teaser__content.bg-primary a{
    display: block;
    margin-top:10px;
}
.teaser .teaser__content.bg-primary a:hover,
.teaser .teaser__content.bg-primary a:focus{
    color: #fff;
}

/* farbvariante black */
.teaser.bg-black .teaser__content {
    background:transparent;
}
.teaser.bg-black h3 a,
.teaser.bg-black h3 {
    color: #fff;
}
.teaser.bg-black .category-icon {
    background: #000;
}
.teaser.bg-black footer {
    border-top:1px solid #606060;
}


@media (min-width: 768px) {
    .teaser {
        padding:0 15px;
    }
    .teaser .teaser__content .has-link-list > ul > li + li {
        margin-top:35px;
    }
    .teaser.teaser--has-border:after,
    .teaser.teaser--has-border:before,
    .row--related-content .teaser:after,
    .row--related-content .teaser:before,
    .pimcore_area_tosc-teaser .teaser:after,
    .pimcore_area_tosc-teaser .teaser:before {
        content:'';
        display: block;
        width:1px;
        height:auto;
        position: absolute;
        right: -0.5px;
        top:0;
        bottom:0;
        background:#dfdfdf;
    }
    .teaser.teaser--has-border:before,
    .row--related-content .teaser:before,
    .pimcore_area_tosc-teaser .teaser:before {
        right:auto;
        left: -0.5px;
    }
    .teaser footer {
        padding: 10px 10px 0 10px;
        margin-top: 10px;
        font-size: 15px;
    }

}

@media (min-width: 1200px) {
    .teaser {
        padding: 0 25px;
    }
}


@media screen and (max-width: 767px) {
    .row--has-teasers .teaser {
        margin-bottom: 20px;
    }
    .row--has-teasers .slick-list .teaser {
        margin-bottom: 0;
    }
    .teaser h3.overlay-headline {
        top: 10px;
    }
}


@media screen and (min-width: 768px) {
    /* hover*/
    .teaser:hover .price {
        padding-right:30px;
    }
    .teaser:hover .category-icon {
        border-radius:0;
        height: 50px;
        margin-top: -33px;
        backface-visibility: hidden;
        transform: rotate(45deg) translateZ(0);
    }
    .teaser:hover .category-icon.category-icon--big {
        height: 80px;
        margin-top: -48px;
    }
    /*.teaser:hover .category-icon:before {
        transform: rotate(-45deg) translateZ(0);
    }*/
    .teaser .teaser-img:after,
    .teaser .teaser-img:before{
        content:' ';
        position: absolute;
        left:8px;
        right:8px;
        top:8px;
        bottom:8px;
        opacity:0;
        z-index:35;
    }

    .teaser .teaser-img:before{
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        transform: scale(0,1);
        transform-origin: 0 0;
    }
    .teaser .teaser-img:after{
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        transform: scale(1,0);
        transform-origin: 100% 0;
    }
    .teaser:hover .teaser-img:after,
    .teaser:hover .teaser-img:before{
        opacity:1;
        transform: scale(1);
    }
    .teaser .teaser-img, .teaser .teaser-img:before, .teaser .teaser-img:after {
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
    }
    .teaser:hover .category-icon:before {
        content: var(--icon-arrow_right);
        font-size:32px;
        transform: rotate(-45deg);
        margin: 0px 2px 0px 2px;
    }




    .teaser .teaser-img.bg-primary:before,
    .teaser .teaser-img.bg-primary:after{
        border-color:var(--bg-primary);
    }
    .teaser .teaser-img.bg-black:before,
    .teaser .teaser-img.bg-black:after{
        border-color:#000;
    }
    .teaser .teaser-img.bg-darkgrey:before,
    .teaser .teaser-img.bg-darkgrey:after{
        border-color:#424242;
    }
    .teaser .teaser-img.bg-grey:before,
    .teaser .teaser-img.bg-grey:after{
        border-color:#f0f0f0;
    }
}


/* TEASER HAS LIST
 --------------------- */

.teaser.teaser--has-list .teaser__content {
    text-align: left;
    padding-top: 0;
    padding-bottom: 10px;
}


/* TEASER WITH SVG-PATTERN */
.teaser--has-svgpattern {
    position: relative;
}
.teaser--has-svgpattern .svg-container {
    display: block;
    position: absolute;
    right: -1px;
    bottom: 3%;
    width: 9%;
}


/* teaser event and hero */
.teaser.teaser--event {
    padding:0;
}
.teaser--event h3 {
    color: #fff;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}
.teaser--event .embed-responsive {
    background:url(/static/img/placeholder-lg.png) 50% 50% no-repeat;
}
.teaser--event-hero .embed-responsive {
    margin-bottom:0;
}
@media screen and (max-width: 767px) {
    .teaser--event .embed-responsive {
        background-size:420px 280px;
    }
}
.teaser.teaser--event-hero {
    position:relative;
    display: block;
}
.teaser--event .teaser__content {
    padding-left:5px;
    padding-right:5px;
}
.teaser--event-hero footer {
    border-color:#6e6f6f;
    color: #fff;
}
@media screen and (min-width: 768px) {
    .teaser.teaser--event {
        margin:20px 0 0 0;
    }

    .teaser.teaser--event:hover:after {
        opacity:1;
    }

}

@media screen and (max-width: 767px) {
    .teaser--event{
        background:#000;
    }
    .slick-slider .teaser {
        padding: 0;
    }
}


/* HERO TEASER UPDATE :::::
--------------------------------  */
.teaser--event-hero.teaser--has-eventpic {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
}
/*.teaser--event-hero.teaser--has-eventpic .teaser-img {
    flex-grow: 1;
}*/
.teaser--event-hero.teaser--has-eventpic .embed-responsive {
    background: none !important;
}
.teaser--event-hero.teaser--has-eventpic .embed-responsive > img {
    display: none;
}
.teaser--event-hero.teaser--has-eventpic .teaser__content {
    background: rgba(0,0,0,0.5);
}
.teaser--event-hero.teaser--has-eventpic .teaser__content .category-icon {
    height: 25px;
    border-radius: 25px 25px 0 0;
}
.teaser.bg-black.teaser--event-hero.teaser--has-eventpic .teaser__content .category-icon {
    background: rgba(0,0,0,0.5);
}
.teaser.bg-black.teaser--event-hero.teaser--has-eventpic .teaser__content .category-icon:before {
    transform: rotate(0deg);
}

@media screen and (min-width: 768px) {
    .teaser.bg-black.teaser--event-hero.teaser--has-eventpic:hover .teaser__content .category-icon {
        height: 50px;
        border-radius: 0;
        background: rgba(0,0,0,1);
    }
    .teaser.bg-black.teaser--event-hero.teaser--has-eventpic:hover .teaser__content .category-icon:before {
        transform: rotate(-45deg);
    }
    .teaser.bg-black.teaser--event-hero.teaser--has-eventpic:hover .teaser__content  {
        background: rgba(0,0,0,1);
    }
}






/* TEASER GRID XS */
@media screen and (max-width: 767px) {
    .row--has-teasers .teaser.teaser--event {
        margin-bottom: 10px;
    }
    .row--has-teasers > .col.col-xs-6:nth-child(odd) {
        padding-left: 5px;
    }
    .row--has-teasers > .col.col-xs-6:nth-child(even) {
        padding-right: 5px;
    }
}

/* TEASER EVENT CSS UPDATE
 ------------------------  */
.teaser--event .teaser__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.teaser--event .teaser__content h3 {
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {
    .teaser--event .teaser__content .teaser__mobile-button {
        flex-grow: 1;
        flex-direction: column;
        justify-content: flex-end;
        display: flex !important;
    }
}

@media screen and (min-width: 768px) {

    .teaser--event .teaser__content h3 {
        flex-grow: 1;
    }
}




/* congress room details */
.teaser__room-details {
    border-top:1px solid #fff;
}
.teaser__room-details.bg-primary{
    background:var(--bg-primary);
}
.teaser__room-details.bg-black{
    background:#000;
}
.teaser__room-details.bg-grey{
    background:#f0f0f0;
}

.teaser__room-details .col + .col{
    border-left:1px solid #fff;
}


.teaser__room-details .room-detail {
    display: inline-block;
    font-size:15px;
    padding:15px 0 15px 18px;
    position: relative;
}
.teaser__room-details .room-detail .icon {
    color:var(--text-primary);
    font-size:20px;
    position: absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
}
.teaser__room-details .room-detail .icon-space {
    left:-10px;
}
@media screen and (max-width: 767px) {
    .teaser__room-details {
        padding:5px 0;
    }
    .teaser__room-details .room-detail {
        font-size:12px;
        padding:5px 0 5px 22px;
    }
    .teaser__room-details .room-detail .icon {
        color:var(--text-primary);
        font-size:16px;
    }
}