#pc-cookie-notice {
    background: var(--bg-primary) !important;
    position: fixed !important;
    bottom:0 !important;
    left:0 !important;
    right:0 !important;
    padding:10px 0 !important;
    font-family: var(--font-default);
    color:#fff !important;
}
#pc-cookie-notice #pc-button {
    top: 0px !important;
}
#pc-cookie-notice #pc-button button {
    position: relative;
    padding: 10px 5px !important;
    background: transparent !important;
    border:0 !important;
    font-weight:300 !important;
    color:#fff !important;
}
#pc-cookie-notice #pc-button button:before {
    position: absolute;
    top:6px;
    left:-15px;
    font-family: iconfont;
    color:#fff;
    content: var(--icon-x);
    font-size:10px;
    padding:5px;
    background: var(--bg-primary) !important;
}
@media screen and (max-width: 767px) {
    #pc-cookie-notice {
        text-align:center;
    }
    #pc-cookie-notice #pc-button {
        position: relative !important;
        right:auto !important;
        top:auto !important;
    }
    #pc-cookie-notice #pc-message {
        padding:0 10px !important;
    }
}