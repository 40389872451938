@media screen and (min-width: 768px) {
    .list-language {
        width: 80%;
        margin: 20px auto 10px;
    }
}

.list-language .lang-flag {
    margin-right: 10px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5);
}

.list-language__item {
    margin-bottom: 25px;
}

.list-language .list-language__link {
    font-size: 18px;
}

.list-language .list-language__link:hover {
    text-decoration: underline;
}


@media screen and (max-width: 767px) {
    .list-language .list-language__link {
        font-size: 14px;
    }
}