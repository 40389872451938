.animated_1s {
    animation-duration: 1s;
}
.animated_05s {
    animation-duration: 0.5s;
}


@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0);
    }

    100% {
        -webkit-transform: none;
        transform: none;
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes staticMapIconAnim {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }

    75% {
        opacity: 1;
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes staticMapIconAnim {
    0%, 60%, 75%, 90%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }

    75% {
        opacity: 1;
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    90% {
        opacity: 1;
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.staticMapIconAnim {
    -webkit-animation-name: staticMapIconAnim;
    animation-name: staticMapIconAnim;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 150%, 0);
        transform: translate3d(0, 150%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 150%, 0);
        transform: translate3d(0, 150%, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

/* SPECIAL FOR EVENTS - TABS
---------------------------- */
@-webkit-keyframes evTabsOrigin {
    0% {
        transform: rotate(135deg) scale(1.20) translateZ(0);
        border-color: #002F5D;
    }

    100% {
        transform: rotate(135deg) scale(1) translateZ(0);
        border-color: #a0a0a0;
    }
}

@keyframes evTabsOrigin {
    0% {
        transform: rotate(135deg) scale(1.2) translateZ(0);
        border-color: #002F5D;
    }

    100% {
        transform: rotate(135deg) scale(1) translateZ(0);
        border-color: #a0a0a0;
    }
}

.evTabsOrigin {
    -webkit-animation-name: evTabsOrigin;
    animation-name: evTabsOrigin;
}


@-webkit-keyframes evTabsHover {
    0% {
        transform: rotate(45deg) scale(1) translateZ(0);
        border-color: #a0a0a0;
        background: none;
    }

    100% {
        transform: rotate(135deg) scale(1.20) translateZ(0);
        border-color: #002F5D;
        background: #fff;
    }
}

@keyframes evTabsHover {
    0% {
        transform: rotate(45deg) scale(1) translateZ(0);
        border-color: #a0a0a0;
        background: none;
    }

    100% {
        transform: rotate(135deg) scale(1.20) translateZ(0);
        border-color: #002F5D;
        background: #fff;
    }
}

.evTabsHover {
    -webkit-animation-name: evTabsHover;
    animation-name: evTabsHover;
}



/* preloader - anim */
@-webkit-keyframes animPreloader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animPreloader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.animPreloader {
    -webkit-animation-name: animPreloader;
    animation-name: animPreloader;
}

