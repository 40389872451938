.video-js .vjs-big-play-button {
    font-size: 1px;
    line-height: 90px;
    height: 90px;
    width: 90px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin:-45px 0 0 -45px;
    border:0;
    background-color: #fff;
    border-radius: 50%;
}
.video-js .vjs-big-play-button:after {
    font-family: iconfont;
    content: var(--icon-play);
    color:#002F5D;
    font-size: 44px;
    margin: 0 0 0 5px;
    text-shadow: none;
} 
.video-js .vjs-big-play-button:before {
    content: "";
}
.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
    border-color: #fff;
    background-color: #fff;
}