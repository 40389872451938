.teaser-wide{
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom:1px solid #dfdfdf;
}
.teaser-wide h3 {
    font-weight:300;
    font-family: var(--font-headline);
    color: var(--text-primary);
}
.teaser-wide .teaser-wide__col-btn {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
}
.teaser-wide .teaser-wide__col-btn .link-arrow {
    position: relative;
}
.teaser-wide .teaser-wide__col-btn .link-arrow .letter {
    display:block;
    width:14px;
    height:14px;
    line-height:14px;
    text-align:center;
    position: absolute;
    left: 6px;
    top: 2px;
    font-size:11px;
    color:#fff;
    transform: translate(0,0);
    transition: transform 100ms;
}
@media screen and (min-width:768px) {
    .teaser-wide .teaser-wide__col-btn .link-arrow .letter {
        left:0.5px;
        top:-1px;
    }
}
.teaser-wide .has-link-list li{
    margin:0 5px;
    float:right;
}
@media screen and (min-width:992px) {
    .teaser-wide .has-link-list li{
        margin:0 10px 0 35px;
    }
}

@media screen and (min-width:1200px) {
    .teaser-wide{
        padding-top:25px;
        padding-bottom:25px;
    }
}

.teaser-wide .embed-responsive {
    background-size:cover;
    background:url(/static/img/placeholder.png) 50% 50% no-repeat;
}
@media screen and (max-width: 767px) {
    .teaser-wide .embed-responsive {
        background-size:280px 140px;
    }
}
