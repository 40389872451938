.sharing {
    padding:25px 0 15px 0;
    position: relative;
    display: inline-block;
    width: 100%;
    visibility: visible;
}
.sharing.sharing--magazin {
    padding:0 0 20px 0;
}
.sharing .hr--has-svg {
    display: block;
}


@media screen and ( min-width: 768px ) {
    .sharing {
        padding:70px 0 0 0;
    }
    .sharing.sharing--magazin {
        padding:10px 0 50px 0;
    }
    .sharing .hr--has-svg, .sharing .hr, .sharing hr {
        margin:25px 0 0 0;
    }
}

#socialmedia {
    float:left;
}
.addthis_sharing_toolbox {
    min-height:52px;
}
@media screen and ( min-width: 768px ) {
    .at-svc-whatsapp,
    .at-svc-messenger,
    .disabled-sharing__btn--messenger,
    .disabled-sharing__btn--whatsapp{
        display: none !important;
    }
}

.activate-sharing {
    position: relative;
    display: block;
    width: 44px;
    margin:0 0 10px 0;
}
.activate-sharing input[type="checkbox"] {
    visibility: hidden;
    margin: 0;
}
.activate-sharing label {
    user-select: none;
    text-indent: -19999px;
}
.activate-sharing span {
    position: absolute;
    top: 1px;
    left: 54px;
    font-size: 15px;
    white-space: nowrap;
}
.activate-sharing label:before {
    content: '';
    position: absolute;
    left:0;
    top: 0;
    width: 44px;
    height: 20px;
    background: #bebebe;
    border: 0;
    text-indent:0;
    border-radius:10px;
    cursor:pointer;
}
.activate-sharing input[type="checkbox"] + label:after {
    content:" ";
    display: block;
    width:20px;
    height:20px;
    position: absolute;
    left:0;
    top:0;
    background:#fff;
    text-indent:0;
    border-radius:50%;
    border:1px solid #bebebe;
    cursor:pointer;
}


.activate-sharing input[type="checkbox"]:checked + label:before {
    background:#1b8221;
    cursor:default;
}
.activate-sharing input[type="checkbox"]:checked + label:after {
    left:auto;
    right:0;
    border:1px solid #1b8221;
    cursor:default;
}



/* disabled sharing */
.disabled-sharing__btn {
    display: inline-block;
    float:left;
    margin: 0 2px 5px 2px;
    padding: 5px;
    overflow: hidden;
    line-height: 0;
    text-decoration: none;
    text-transform: none;
    color: #fff;
    transition: all .2s ease-in-out;
    border: 0;
    background: #bebebe;
}
.disabled-sharing__icon {
    display: inline-block;
    overflow: hidden;
    line-height: 32px;
    height: 32px;
    width: 32px;
}




/* magazin sharing */
