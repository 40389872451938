.filterbox {
    padding: 15px 10px;
    color: #fff;
    text-align: center;
}
.filterbox.filterbox--no-bg {
    color: var(--text-default);
    background-color: transparent;
}

.filterbox__list > li {
    border-bottom: 1px solid rgba(255,255,255,0.6);
    position: relative;
}

.filterbox__list > li .dropdown-string{
    color:var(--text-primary);
}

@media screen and ( min-width: 768px ) {
    .filterbox__list > li.min-width-33 {
        min-width:33%;
    }
    .filterbox__list > li .dropdown-string{
        text-align: center;
        padding-right:20px;
    }
}

@media screen and ( max-width: 767px ) {
    .filterbox {
        margin-bottom: 15px;
        padding: 15px 20px;
    }
    .filterbox__list,
    .filterbox__list > li {
        display:block;
        text-align: left;
    }
    .filterbox > .filterbox__list + .filterbox__list {
        margin: 0;
    }
    .filterbox .date-picker .icon {
        top: 18px;
    }
    .filterbox--portal {
        color: #424242;
        margin-bottom:20px;
        background: transparent;
    }
}


/* FILTER ::: DATE-RANGE
 ---------------------------- */
.filterbox__list .input-daterange .input-wrap {
    padding: 0;
    position: relative;
}

@media screen and ( max-width: 767px ) {
    .filterbox__list > li.has-datepicker.li--has-daterangepicker {
        padding:0;
    }
    .filterbox__list > li.has-datepicker.li--has-daterangepicker .input-wrap {
        padding: 15px 0;
    }
    .filterbox__list .input-daterange .input-wrap + .input-wrap {
        border-top: 1px solid #d7d7d7;
    }
}


@media screen and ( min-width: 768px ) {
    .filterbox__list {
        display: inline-block;
    }
    .filterbox__list > li {
        display: inline-block;
    }
    .filterbox__list > li.has-datepicker.li--has-daterangepicker {
        border-bottom: none;
        margin: 0;
        padding:0;
    }
    .filterbox__list > li .form-control--input {
        border:0;
        padding-top:12px;
        height:auto;
        font-size:inherit;
        color: #606060;
    }
    .filterbox__list > li .form-control--input::-moz-placeholder {
        color: #606060;
    }
    .filterbox__list > li .form-control--input:-ms-input-placeholder {
        color: #606060;
    }
    .filterbox__list > li .form-control--input::-webkit-input-placeholder {
        color: #606060;
    }
    .filterbox__list > li.has-datepicker.li--has-daterangepicker .input-daterange .input-wrap {
        border-bottom: 1px solid #dfdfdf;
        margin: 0 30px;
        display: inline-block;
        padding:8px 0;
    }
    .slide-sbginfo-tabs--content .filterbox__list > li.has-datepicker.li--has-daterangepicker .input-daterange .input-wrap {
        border-bottom: 1px solid #606060;
    }

}



@media screen and (max-width: 767px) {
    /* FILTERBOX TOGGLER ON XS..  */
    .filterbox--mobile {
        display: none;
    }
    .filterbox--mobile {
        position: absolute;
        top: 39px;
        left: 3%;
        right: 3%;
        z-index: 150;
        background-color: #fff !important;
        border: 3px solid #bebebe;
    }
}


/* FILTER ::: JS-DROPDOWN
 ---------------------------- */
@media screen and ( max-width: 768px ) {

    .filterbox__list li.li--has-js-dropdown .dropdown-toggle .dropdown-string {
        text-align: left;
        overflow: hidden;
        color: var(--text-primary);
        padding-right: 15px;
    }

    .filterbox__list li.xs-no-border {
        border-bottom: none;
    }
}

@media screen and ( min-width: 768px ) {
    .filterbox__list li.li--has-js-dropdown > .dropdown-toggle {
        min-width: 100%;
        display: block;
        padding:10px 0;
    }
    .filterbox__list li.li--has-js-dropdown > .dropdown-toggle .icon {
        position: relative;
        top:12px;
    }
    .filterbox__list li.li--has-js-dropdown > .dropdown-toggle:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
    .filterbox + .btn {
        margin: 20px 0 0 0;
    }
    .filterbox__list > li {
        margin-left: 30px;
        margin-right: 30px;
        text-align: left;
        padding:0;
    }
    .filterbox__list > li.has-datepicker {
        padding:8px 0;
    }
    .filterbox--no-bg .filterbox__list > li:not(.has-datepicker) {
        border-bottom: 1px solid #dfdfdf;
    }
    .filterbox__list > li:first-of-type { margin-left: 0; }
    .filterbox__list > li:last-of-type { margin-right: 0; }
}


/* FILERBOX -- SINGLE_DATEPICKER */
@media screen and (max-width: 767px) {
    .filterbox label {
        width:80px;
        display: inline-block;
        float: left;
    }
    .filterbox .checkbox-wrap label,
    .filterbox .radio-wrap label{
        width:auto;
        float:none;
        clear:both;
        padding-top: 2px;
    }
}

/* FILERBOX -- DATERANGEPICKER */

@media screen and (max-width: 767px) {
    .filterbox__list > li {
        border-bottom:1px solid #d7d7d7;
        padding: 15px 0;
    }
    .filterbox__list > li.noborder {
        border-bottom:0;
    }
    .filterbox__list > li .radio-wrap,
    .filterbox__list > li .checkbox-wrap {
        margin: 0;
    }

}


@media screen and (min-width: 768px) {
    .filterbox.filterbox--no-bg .date-picker .input-wrap {
        border-bottom: 1px solid #dfdfdf;
    }
}


.filterbox label {
    display: inline;
    width:auto;
    font-weight:300;
    margin-bottom:0;
}
.filterbox .date-picker {
    display: block;
}
.filterbox .date-picker .icon {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 12px;
}
.filterbox .date-picker .form-control {
    background: none;
    border: none;
    color: var(--text-primary);
    padding:0;
    font-size: inherit;
    cursor: pointer;
    width: calc(100% - 80px);
    height:auto;
    display: inline-block;
}
@media screen and (min-width: 768px) {
    .filterbox .date-picker .form-control {
        padding: 0 30px 0 10px;
        width: auto;
    }
}


.filterbox--portal .dropdown-toggle:hover,
.filterbox--portal .dropdown-toggle:focus {
    color:#424242;
}
/* filterbox-dropdown */
@media screen and (min-width: 768px) {
    .filterbox .dropdown-toggle {
        position: relative;
    }
}

.filterbox .dropdown-toggle:after {
    content: '';
    position: absolute;
    top:18px;
    right:0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #002F5D transparent transparent transparent;
    transition: all 0.5s;
}
.filterbox .open .dropdown-toggle:after {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #002F5D transparent;
}
.filterbox li .dropdown-menu {
    background: #000;
    width: auto;
    min-width: 100%;
    margin: 0;
    padding: 10px 0;
}
.filterbox li .dropdown-menu li span,
.filterbox li .dropdown-menu li a {
    color: #fff;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    cursor: pointer;
}
.filterbox li .dropdown-menu li span:hover,
.filterbox li .dropdown-menu li a:hover,
.filterbox li .dropdown-menu li span:focus,
.filterbox li .dropdown-menu li a:focus {
    color: #002F5D;
}

@media screen and (max-width: 767px) {

    .filterbox li .dropdown-menu {
        max-height: 75vh;
        max-height: 75vmax;
        overflow: scroll;
    }

}

/* FILTERBOX NO-BG
--------------------------- */
.filterbox.filterbox--no-bg li .dropdown-menu {
    background: #fff;
    width: auto;
    padding: 5px 0;
    border: 1px solid #dfdfdf;
}
.filterbox.filterbox--no-bg li .dropdown-menu li a,
.filterbox.filterbox--no-bg li .dropdown-menu li span {
    color: #000;
    padding: 5px 15px;
    white-space: nowrap;
    display: block;
    cursor: pointer;
}
@media screen and (min-width: 767px) {
    .filterbox.filterbox--no-bg li .dropdown-menu {
        box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
        padding: 10px;
    }

}
@media screen and (min-width: 992px) {
    .filterbox.filterbox--no-bg li .dropdown-menu li.active a,
    .filterbox.filterbox--no-bg li .dropdown-menu li a:focus,
    .filterbox.filterbox--no-bg li .dropdown-menu li a:focus {
        background: var(--text-primary);
        color: #fff;
    }
}


/* DROPDOWNS MULTICOLUMNAS
 ------------------------- */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    .dropdown-menu--2-columns,
    .dropdown-menu--3-columns {
        column-count: 2;
        column-gap: 0;
        column-rule: 1px outset #dfdfdf;
    }

    .dropdown-menu--2-columns > li,
    .dropdown-menu--3-columns > li {
        break-inside: avoid;
    }
}
@media screen and (min-width: 1200px) {

    .dropdown-menu--2-columns {
        column-count: 2;
        column-gap: 0;
        column-rule: 1px outset #dfdfdf;
    }
    .dropdown-menu--3-columns {
        column-count: 3;
        column-gap: 0;
        column-rule: 1px outset #dfdfdf;
    }

    .dropdown-menu--2-columns > li,
    .dropdown-menu--3-columns > li {
        break-inside: avoid;
    }
}