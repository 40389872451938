.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: default;
}
.mfp-bg {
    background-color: #1e1e1e;
    opacity: 0.95;
    z-index: 5042;
}
.mfp-wrap {
    z-index: 5043;
}
.mfp-content {
    z-index: 5045;
}
img.mfp-img {
    padding:0;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
    border:15px solid #fff;
}

button.mfp-close,
button.mfp-close:hover,
button.mfp-close:focus,
button.mfp-close:active {
    color: #e7e7e7;
    font-size: 30px;
    overflow: hidden;
    text-indent:-19999px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    top: 15px;
    right: 15px;
}
button.mfp-close,
button.mfp-arrow {
    z-index: 5046;
}
button.mfp-close:before {
    content: var(--icon-x);
    display: block;
    text-indent: 0;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.mfp-figure:after {
    box-shadow: none;
    background: transparent;
}
.mfp-bottom-bar {
    margin-top: 10px;
}
.mfp-title{
    padding-right: 0;
    text-align: center;
}

.mfp-arrow:before {
    display:none;
}
.mfp-arrow:after {
    border-top-width: 18px;
    border-bottom-width: 18px;
}
.mfp-arrow-left:after {
    border-right: 26px solid #FFF;
}
.mfp-arrow-right:after {
    border-left: 26px solid #FFF;
}
.mfp-iframe-scaler {
    width: 980px;
    height: 720px;
    padding: 0;
}
.mfp-iframe-scaler iframe {
    position: relative;
    top: auto;
    left: auto;
    box-shadow: none;
    background: #fff;
}
.mfp-iframe-holder .mfp-content {
    width: 1010px;
    max-width: 1010px;
    border: 15px solid #fff;
}

/* inline type */
.mfp-inline-content{
    position: relative;
    background: #FFF;
    padding:15px;
    width: auto;
    max-width: 900px;
    margin: 20px auto;
}