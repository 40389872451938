/* content-block */
.content-block {
    margin: 20px 0;
    float:none;
    clear:both;
}
.hl-with-lines + .content-block {
    margin: 10px 0 20px 0;
}

.content-block--portalteaser {
    border:1px solid #dfdfdf;
    border-width:1px 0;
}

@media screen and (min-width: 768px) {
    .content-block {
        margin: 25px 0;
    }
    .hl-with-lines + .content-block {
        margin: 10px 0 30px 0;
    }
    .content-block.content-block--xl {
        margin: 30px 0;
    }
    .content-block + .content-block {
        margin-top: 25px;
    }
    .content-block > .row.row--has-margin-top + .row.row--has-margin-top {
        margin-top: 20px;
    }
}
@media screen and (min-width: 1200px) {
    #main .pimcore_area_content .content-block:not(.content-block--no-maxwidth) {
        position: relative;
        max-width:1080px;
        margin-left:auto;
        margin-right:auto;
    }
    .content-block {
        margin: 35px 0;
    }
    .content-block--portalteaser {
        margin: 50px 0;
    }
    .hl-with-lines + .content-block {
        margin: 10px 0 70px 0;
    }
    .content-block.content-block--xl {
        margin: 70px 0;
    }
    .content-block + .content-block {
        margin-top: 50px;
    }
    .content-block > .row.row--has-margin-top + .row.row--has-margin-top {
        margin-top: 40px;
    }
}

/*  */
@media screen and (max-width: 768px) {
    .content-block--additional-teasers {
        overflow-x:hidden;
    }
}
