@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1728476774356/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1728476774356/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1728476774356/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
                     font-size: 1.3333333333333333em;
                     line-height: 0.75em;
                     vertical-align: -15%;
                 }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                     width: 1.2857142857142858em;
                     text-align: center;
                 }

.icon-ul {
                     padding-left: 0;
                     margin-left: 2.14285714em;
                     list-style-type: none;
                 }
.icon-ul > li {
                     position: relative;
                 }
.icon-li {
                     position: absolute;
                     left: -2.14285714em;
                     width: 2.14285714em;
                     top: 0.14285714em;
                     text-align: center;
                 }
.icon-li.icon-lg {
                                         left: -1.85714286em;
                                     }

.icon-rotate-90 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                     -webkit-transform: rotate(90deg);
                     -ms-transform: rotate(90deg);
                     transform: rotate(90deg);
                 }
.icon-rotate-180 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
                     -webkit-transform: rotate(180deg);
                     -ms-transform: rotate(180deg);
                     transform: rotate(180deg);
                 }
.icon-rotate-270 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                     -webkit-transform: rotate(270deg);
                     -ms-transform: rotate(270deg);
                     transform: rotate(270deg);
                 }
.icon-flip-horizontal {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
                     -webkit-transform: scale(-1, 1);
                     -ms-transform: scale(-1, 1);
                     transform: scale(-1, 1);
                 }
.icon-flip-vertical {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
                     -webkit-transform: scale(1, -1);
                     -ms-transform: scale(1, -1);
                     transform: scale(1, -1);
                 }
:root .icon-rotate-90,
                       :root .icon-rotate-180,
                                              :root .icon-rotate-270,
                                                                     :root .icon-flip-horizontal,
                                                                                            :root .icon-flip-vertical {
                                                                                                                       filter: none;
                                                                                                                   }

.icon-besucher:before { content: "\E035" }
.icon-brauerei:before { content: "\E036" }
.icon-cafe:before { content: "\E037" }
.icon-clock:before { content: "\E038" }
.icon-fast-food:before { content: "\E039" }
.icon-infrastruktur:before { content: "\E03A" }
.icon-linkedin:before { content: "\E03B" }
.icon-person:before { content: "\E03C" }
.icon-phone:before { content: "\E03D" }
.icon-restaurant-haube:before { content: "\E03E" }
.icon-sc:before { content: "\E03F" }
.icon-space:before { content: "\E040" }
.icon-sponsoren:before { content: "\E041" }
.icon-arrow_down:before { content: "\E001" }
.icon-arrow_right:before { content: "\E002" }
.icon-art:before { content: "\E003" }
.icon-bars:before { content: "\E004" }
.icon-bed:before { content: "\E005" }
.icon-bierlokal:before { content: "\E006" }
.icon-bus:before { content: "\E007" }
.icon-café:before { content: "\E008" }
.icon-check:before { content: "\E009" }
.icon-close:before { content: "\E00A" }
.icon-date:before { content: "\E00B" }
.icon-deg_360:before { content: "\E00C" }
.icon-elements:before { content: "\E00D" }
.icon-events_chosen:before { content: "\E00E" }
.icon-events_unchosen:before { content: "\E00F" }
.icon-facebook:before { content: "\E010" }
.icon-folder:before { content: "\E011" }
.icon-geoeffnet:before { content: "\E012" }
.icon-go_to_map:before { content: "\E013" }
.icon-google_plus:before { content: "\E014" }
.icon-impressionen:before { content: "\E015" }
.icon-instagram:before { content: "\E016" }
.icon-kontakt:before { content: "\E017" }
.icon-language:before { content: "\E018" }
.icon-list:before { content: "\E019" }
.icon-list_item:before { content: "\E01A" }
.icon-logo:before { content: "\E01B" }
.icon-magazine:before { content: "\E01C" }
.icon-map_marker:before { content: "\E01D" }
.icon-oeffnungszeiten:before { content: "\E01E" }
.icon-oeffnungszeiten_preise:before { content: "\E01F" }
.icon-open:before { content: "\E020" }
.icon-package:before { content: "\E021" }
.icon-pinterest:before { content: "\E022" }
.icon-play:before { content: "\E023" }
.icon-preis:before { content: "\E024" }
.icon-restaurant:before { content: "\E025" }
.icon-sbg_card:before { content: "\E026" }
.icon-scroll_down:before { content: "\E027" }
.icon-search:before { content: "\E028" }
.icon-shopping:before { content: "\E029" }
.icon-sight_seeing:before { content: "\E02A" }
.icon-slider:before { content: "\E02B" }
.icon-teaser_grid:before { content: "\E02C" }
.icon-thin_arrow_right:before { content: "\E02D" }
.icon-ticket:before { content: "\E02E" }
.icon-twitter:before { content: "\E02F" }
.icon-txt:before { content: "\E030" }
.icon-webcam:before { content: "\E031" }
.icon-wetter:before { content: "\E032" }
.icon-x:before { content: "\E033" }
.icon-youtube:before { content: "\E034" }
.icon-veranstalter:before { content: "\E042" }
.icon-wein:before { content: "\E043" }


:root {
--icon-besucher: "\E035";
    --icon-brauerei: "\E036";
    --icon-cafe: "\E037";
    --icon-clock: "\E038";
    --icon-fast-food: "\E039";
    --icon-infrastruktur: "\E03A";
    --icon-linkedin: "\E03B";
    --icon-person: "\E03C";
    --icon-phone: "\E03D";
    --icon-restaurant-haube: "\E03E";
    --icon-sc: "\E03F";
    --icon-space: "\E040";
    --icon-sponsoren: "\E041";
    --icon-arrow_down: "\E001";
    --icon-arrow_right: "\E002";
    --icon-art: "\E003";
    --icon-bars: "\E004";
    --icon-bed: "\E005";
    --icon-bierlokal: "\E006";
    --icon-bus: "\E007";
    --icon-café: "\E008";
    --icon-check: "\E009";
    --icon-close: "\E00A";
    --icon-date: "\E00B";
    --icon-deg_360: "\E00C";
    --icon-elements: "\E00D";
    --icon-events_chosen: "\E00E";
    --icon-events_unchosen: "\E00F";
    --icon-facebook: "\E010";
    --icon-folder: "\E011";
    --icon-geoeffnet: "\E012";
    --icon-go_to_map: "\E013";
    --icon-google_plus: "\E014";
    --icon-impressionen: "\E015";
    --icon-instagram: "\E016";
    --icon-kontakt: "\E017";
    --icon-language: "\E018";
    --icon-list: "\E019";
    --icon-list_item: "\E01A";
    --icon-logo: "\E01B";
    --icon-magazine: "\E01C";
    --icon-map_marker: "\E01D";
    --icon-oeffnungszeiten: "\E01E";
    --icon-oeffnungszeiten_preise: "\E01F";
    --icon-open: "\E020";
    --icon-package: "\E021";
    --icon-pinterest: "\E022";
    --icon-play: "\E023";
    --icon-preis: "\E024";
    --icon-restaurant: "\E025";
    --icon-sbg_card: "\E026";
    --icon-scroll_down: "\E027";
    --icon-search: "\E028";
    --icon-shopping: "\E029";
    --icon-sight_seeing: "\E02A";
    --icon-slider: "\E02B";
    --icon-teaser_grid: "\E02C";
    --icon-thin_arrow_right: "\E02D";
    --icon-ticket: "\E02E";
    --icon-twitter: "\E02F";
    --icon-txt: "\E030";
    --icon-webcam: "\E031";
    --icon-wetter: "\E032";
    --icon-x: "\E033";
    --icon-youtube: "\E034";
    --icon-veranstalter: "\E042";
    --icon-wein: "\E043";
    
}