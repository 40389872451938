.row--same-height > .col > .row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    /*flex-wrap: wrap;*/
}
.row--same-height {
    display: flex;
 /*   flex-direction: row;*/
    flex-wrap: wrap;
    /*align-items: stretch;*/
    /*justify-content: space-between;*/
    /*overflow: hidden;*/
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}
.row--same-height > .col {
    display: flex;
    flex-direction: column;
    height: auto;
}

@media screen and (max-width: 767px) {
    .row--same-height.disable-on-xs
    /*,*/
    /*.row--same-height.disable-on-xs > .col,*/
    /*.row--same-height.disable-on-xs > .col > .row--same-height__item*/
    {
        display: block;
    }
}