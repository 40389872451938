@media screen and (min-width: 768px) {
    .row.gutter-width-100 {
        margin:0 -50px;
    }
    .row.gutter-width-100>.col {
        padding:0 50px;
    }
    .row.gutter-width-70 {
        margin:0 -35px;
    }
    .row.gutter-width-70>.col {
        padding:0 35px;
    }
    .row.gutter-width-50 {
        margin:0 -25px;
    }
    .row.gutter-width-50>.col {
        padding:0 15px;
    }
    .row.gutter-width-40 {
        margin:0 -20px;
    }
    .row.gutter-width-40>.col {
        padding:0 20px;
    }
    .row.gutter-width-30 {
        margin:0 -15px;
    }
    .row.gutter-width-30>.col {
        padding:0 15px;
    }
    .row.gutter-width-20 {
        margin:0 -10px;
    }
    .row.gutter-width-20>.col {
        padding:0 10px;
    }
}

@media screen and (min-width: 992px) {
    .row.gutter-width-50>.col {
        padding:0 25px;
    }
}

.row.gutter-width-0 {
    margin: 0;
}
.row.gutter-width-0>.col {
    padding:0;
}
.row.gutter-width-10 {
    margin:0 -5px;
}
.row.gutter-width-10>.col {
    padding:0 5px;
}

@media screen and (min-width: 768px) {
    .row--center-cols {
        font-size: 0;
        text-align: center;
    }
    .row--center-cols > .col {
        display: inline-block;
        float: none;
        vertical-align: top;
        text-align: left;
    }
}