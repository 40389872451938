
/* RESULT BAR
 ---------------------  */
@media screen and (max-width: 767px) {

    .result-bar {
        padding: 10px 0;
        text-align: center;
    }
    .result-bar__item {
        padding: 5px 0;
    }

}
@media screen and (min-width: 768px) {
    .result-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .result-bar.result-bar--bottom {
        justify-content: flex-end;
    }

    .items-per-page,
    .paging{
        display: flex;
        align-items: center;
    }
    .items-per-page > a,
    .paging > a {
        padding: 10px 8px;
    }
    .items-per-page > a.active,
    .items-per-page > a:hover,
    .paging > a.active,
    .paging > a:hover{
        padding: 10px 8px;
        background: #e3e3e3;
        color: inherit;
        transition: background 0.2s ease;
    }
}

.result-bar-left {
    margin-top:10px;
}