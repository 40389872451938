/*.filter-categories a.filter-categories__main-cat {*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*padding:15px 10px;*/
    /*margin:1px 0;*/
    /*background:#f8f8f8;*/
    /*font-family: var(--font-headline);*/
    /*font-weight: 300;*/
/*}*/
/*.filter-categories a.filter-categories__main-cat:before {*/
    /*font-size:1.5em;*/
/*}*/
/*.filter-categories .filter-categories__info {*/
    /*padding: 0 0 15px 0;*/
    /*border-bottom:1px solid #dfdfdf;*/
/*}*/

/*.filter-categories .filter-categories__checkboxes {*/
    /*padding:15px 0;*/
/*}*/
.filter-categories .hr--has-arrow {
    margin:20px 0;
}

/*@media screen and ( max-width: 767px ) {*/
    /*.filter-categories .checkbox-wrap {*/
        /*padding: 10px 5px;*/
    /*}*/
/*}*/

@media screen and ( min-width: 768px ) {
    .filter-categories .filter-categories__images,
    .filter-categories .filter-categories__packages {
        text-align: center;
    }
    /*.filter-categories .filter-categories__info {*/
        /*padding:0 0 35px 0;*/
    /*}*/
    /*.filter-categories a.filter-categories__main-cat {*/
        /*padding:45px 0;*/
    /*}*/
    .filter-categories .filter-categories__checkboxes {
        padding: 20px 0 0 0;
    }
    .filter-categories .checkbox-wrap {
        margin: 20px;
        display: inline-block;
    }
    .filter-categories .hr--has-arrow {
        margin:35px 0 70px 0;
    }
}