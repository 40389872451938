.gallery-grid {
    margin-bottom: 110px;
}
.gallery-grid__item {
    position: relative;
    margin-bottom: 10px;
}
.gallery-grid-container {
    margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
    .gallery-grid-container {
        margin: -12px;
    }
    .gallery-grid__item {
        margin-bottom: 0;
        position: absolute;
        border: 12px solid white;
    }
    .gallery-grid__item:nth-child(1) {
        top: 0;
        left: 0;
        width: 57.4%;
    }
    .gallery-grid__item:nth-child(2) {
        right: 0;
        top: 6.3%;
        width: 49.6%;
    }
    .gallery-grid--small .gallery-grid__item:nth-child(2) {
        top: 9.8%;
    }
    .gallery-grid__item:nth-child(3) {
        bottom: 5%;
        right: 10%;
        width: 33.3333333%;
    }
    .gallery-grid__item:nth-child(4) {
        bottom: -7px;
        left: 11%;
        width: 46.5%;
    }
}
.gallery-grid__item__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.icon.gallery-grid__item__overlay__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 80px;
}