/* square teaser */
.teaser.teaser--team{
    padding:0;
    background: #f0f0f0;
}
.teaser.teaser--team:hover .teaser-img:after, .teaser.teaser--team:hover .teaser-img:before {
    opacity: 0;
}

.teaser.teaser--team:after,
.teaser.teaser--team:before {
    display: none;
}
.teaser--team .teaser__content {
    padding: 20px 8px;
    min-height: 0;
    text-align: center;
    background: #f0f0f0;
}
@media (min-width: 768px) {
    .teaser.teaser.teaser--team {
        margin-top:20px;
    }
    .teaser.teaser--team .teaser__content {
        padding: 25px 10px;
    }
}
@media (max-width: 767px) {
    .teaser.teaser--team {
        margin-bottom:20px;
    }
}