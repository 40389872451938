@media screen and (max-width: 767px) {
    .number-spinner {
        margin-left:-15px;
        top:-14px;
        position: relative;
        display: inline-block;
    }
    .number-spinner .btn{
        position: absolute;
        top:0;
        width:42px;
        text-align:center;
        height:42px;
        line-height:42px;
        background:transparent;
        padding:0;
    }
    .number-spinner__label{
        position: relative;
    }
    .number-spinner .btn.number-spinner__down{
        left:0;
    }
    .number-spinner .btn.number-spinner__up{
        right:0;
    }
    .number-spinner .btn.number-spinner__down:before{
        content:' ';
        position: absolute;
        top:20px;
        left:16px;
        width:10px;
        height:2px;
        background:var(--bg-primary);
    }

    .number-spinner .spinner-input{
        background: transparent;
        border:0;
        color:var(--text-primary);
        text-align:center;
        width:125px;
        height:42px;
    }
    .number-spinner .btn-link .icon{
        font-size:10px;
    }
}