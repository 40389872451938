/* square teaser */
.teaser.teaser--topic{
    padding:0;
}

.teaser.teaser--topic:after,
.teaser.teaser--topic:before {
    display: none;
}
.teaser--topic .teaser__content {
    background: #fff;
    padding: 20px 15px 5px 15px;
    min-height: 0;
    text-align: center;
}
.teaser--topic .teaser__content.bg-darkgrey {
    background: #424242;
    color:#fff;
}
.teaser--topic .teaser__content.bg-darkgrey h3,
.teaser--topic .teaser__content.bg-darkgrey h3 a {
    color:inherit;
}
.teaser--topic .teaser__content.bg-grey {
    background: #f0f0f0;
}
.teaser--topic .category-icon.category-icon{
    border-radius:0;
    font-size: 10px;
    transform: rotate(45deg);
}
.teaser--topic .category-icon:before {
    transform: rotate(0deg);
}
.teaser--topic .category-icon.category-icon.bg-darkgrey {
    background: #424242;
    color:#fff;
}
.teaser--topic .category-icon.category-icon.bg-grey {
    background: #f0f0f0;
}
@media (min-width: 768px) {
    .teaser.teaser.teaser--topic {
        margin-top:20px;
    }
    .teaser.teaser--topic .teaser__content {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 15px 10px 15px;
    }
    .teaser--topic .category-icon.category-icon{
        width:40px;
        height:40px;
        line-height:40px;
        margin:-20px 0 0 -20px;
        font-size: 14px;
    }
    .teaser--topic:hover .category-icon.category-icon{
        width:40px;
        height:40px;
        line-height:40px;
        margin:-28px 0 0 -20px;
    }
    .teaser.teaser--topic:hover .category-icon:before {
        font-size:26px;
    }
}
@media (max-width: 767px) {
    .teaser.teaser--topic {
        margin-bottom:10px;
    }
}


@media (max-width: 767px) {
    .row--has-topic-teasers > .col.col-xs-6:nth-child(odd) {
        padding-right: 5px;
    }
    .row--has-topic-teasers > .col.col-xs-6:nth-child(even) {
        padding-left: 5px;
    }
    .row--has-topic-teasers .teaser--topic .teaser__content {
        padding: 20px 5px 5px;
        flex-grow: 1;
    }
}