/* SKEWED ICONS
 --------------------------- */
.skew-icon {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    font-size: 13px;
}
.skew-icon:after {
    content: '';
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    z-index: -1;
    background: var(--bg-primary);
    transform: rotate(45deg) translateZ(0);
    transition: all 0.15s ease-out;
}

@media screen and (min-width: 768px) {
    .skew-icon {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
    .skew-icon.icon-clock {
        font-size: 28px;
    }
    .skew-icon--lg {
        width: 66px;
        height: 66px;
        font-size: 34px;
    }
}
