/* Link arrow  */
.link-arrow.link-arrow {
    line-height: 1;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
}
.link-arrow span {
    text-decoration:none;
}
.link-arrow i {
    font-style: normal;
}
.link-arrow.link-arrow:hover,
.link-arrow.link-arrow:focus {
    text-decoration: none;
    color: var(--text-primary);
}
.link-arrow.link-arrow:hover span,
.link-arrow.link-arrow:focus span {
    text-decoration: underline;
}
.link-arrow:before {
    content: var(--icon-arrow_right);
    font-size: 22px;
    margin-right: 15px;
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0,0);
    transition: transform 100ms;
    position: relative;
    top: -1px;
    text-decoration: none !important;
}
.link-arrow.link-marker:before {
    content: var(--icon-map_marker);
    /*font-size: 170%;*/
}
/*.link-arrow:hover:before {*/
    /*transform: translateX(5px);*/
/*}*/
.link-arrow + .link-arrow,
.has-link-list > ul > li + li {
    margin-top: 5px;
}

/* Link arrow list */
.wysiwyg .has-link-list > ul,
.has-link-list > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
}

@media screen and (max-width: 767px) {
    .link-arrow.link-arrow {
        padding: 5px 7px;
    }
    .link-arrow:before {
        top: -2px;
    }
}

@media screen and (min-width: 768px) {
    .link-arrow + .link-arrow,
    .has-link-list > ul > li + li {
        margin-top: 10px;
    }
}