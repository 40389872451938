:root {
    --arrange-gutter-size: 10px;
}
.arrange {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: table;
    margin: 0;
    min-width: 100%;
    padding: 0;
    table-layout: auto;
}

.arrange-sizeFill,
.arrange-sizeFit {
    display: table-cell;
    padding: 0;
    vertical-align: top;
}
.arrange-sizeFill {
    width: 100%;
}
.arrange-sizeFill img {
    height: auto;
    max-width: 100%;
}
.arrange-sizeFit img {
    max-width: none !important;
    width: auto !important;
}

.arrange--middle .arrange-sizeFill,
.arrange--middle .arrange-sizeFit,
.arrange-sizeFill--medium {
    vertical-align: middle;
}

.arrange--bottom .arrange-sizeFill,
.arrange--bottom .arrange-sizeFit {
    vertical-align: bottom;
}

.arrange--equal {
    table-layout: fixed;
    width: 100%; /* 1 */
}
.arrange--equal > .arrange-sizeFill,
.arrange--equal > .arrange-sizeFit {
    width: 1%;
}

.arrange--withGutter {
    margin: 0 calc(-0.5 * var(--arrange-gutter-size));
}
.arrange--withGutter.arrange--equal {
    width: calc(100% + 10px);
}

.arrange--withGutter > .arrange-sizeFit,
.arrange--withGutter > .arrange-sizeFill {
    padding: 0 calc(0.5 * var(--arrange-gutter-size));
}

.arrange--withGutter-20 {
    margin: 0 calc(-0.5 * 20px);
}
.arrange--withGutter-20 > .arrange-sizeFit,
.arrange--withGutter-20 > .arrange-sizeFill {
    padding: 0 calc(0.5 * 20px);
}

.arrange--withGutter-15 {
    margin: 0 calc(-0.5 * 15px);
}
.arrange--withGutter-15.arrange--equal {
    width: calc(100% + 15px);
}
.arrange--withGutter-15 > .arrange-sizeFit,
.arrange--withGutter-15 > .arrange-sizeFill {
    padding: 0 calc(0.5 * 15px);
}

.arrange--withGutter-4 {
    margin: 0 calc(-0.5 * 4px);
}
.arrange--withGutter-4 > .arrange-sizeFit,
.arrange--withGutter-4 > .arrange-sizeFill {
    padding: 0 calc(0.5 * 4px);
}

.arrange--withGutter-8 {
    margin: 0 calc(-0.5 * 8px);
}
.arrange--withGutter-8 > .arrange-sizeFit,
.arrange--withGutter-8 > .arrange-sizeFill {
    padding: 0 calc(0.5 * 8px);
}
/*.arrange--withGutter-1 {*/
    /*padding: 0 1px 0 0;*/
/*}*/
/*.arrange--withGutter-1 > .arrange-sizeFit,*/
/*.arrange--withGutter-1 > .arrange-sizeFill {*/
    /*padding: 0 1px 0 0;*/
/*}*/

@media screen and (max-width: 767px) {
    .arrange--disableOnXs,
    .arrange--disableOnXs > .arrange-sizeFill,
    .arrange--disableOnXs > .arrange-sizeFit {
        display: block;
    }

}

