.gallery {
    position: relative;
}

.gallery-main .slick-thumb-arrow {
    display: none;
}
@media screen and (min-width: 768px) {
    .gallery-main.slick-slider {
        overflow: hidden;
    }
    .gallery-main .slick-thumb-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
     /*   opacity: 1;*/
        transition: opacity 150ms;
        width: 50px;
        height: 50px;
        text-decoration: none !important;
    }
    .gallery-main .slick-thumb-arrow.slick-thumb-arrow--prev:before,
    .gallery-main .slick-thumb-arrow.slick-thumb-arrow--next:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        background: rgba(255,255,255,0.70);
        transform: rotate(-45deg);
        transform-origin: center;
        width: 100px;
        height: 100px;
        left: -115%;
        top: -50%;
        z-index: -1;
    }
    .gallery-main .slick-thumb-arrow.slick-thumb-arrow--next:after {
        left: auto;
        right: -115%;
        top: -50%;
        z-index: -1;
    }

}
@media screen and (min-width: 992px) {
    html.desktop .gallery-main .slick-thumb-arrow {
        opacity: 0;
    }
    html.desktop .gallery-main:hover .slick-thumb-arrow:not(.slick-disabled) {
        opacity: 1;
    }
}


@media screen and (max-width: 767px) {
    .gallery-main--modal.slick-slider {
        overflow: hidden;
    }
    .gallery-main--modal .slick-thumb-arrow {
        padding:10px;
    }
    .gallery-main--modal .slick-thumb-arrow.slick-thumb-arrow--prev:before,
    .gallery-main--modal .slick-thumb-arrow.slick-thumb-arrow--next:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        background: rgba(255,255,255,0.70);
        transform: rotate(-45deg);
        transform-origin: center;
        width: 80px;
        height: 80px;
        left: -100%;
        top: -50%;
        margin-top:-5px;
        z-index: -1;
    }
    .gallery-main--modal .slick-thumb-arrow.slick-thumb-arrow--next:after {
        left: auto;
        right: -100%;
        top: -50%;
        z-index: -1;
    }
}



.gallery-thumb .slick-slide {
    margin:10px;
    padding:3px;
    border: 1px solid #fff;
    cursor: pointer;
}
.gallery-thumb .slick-current {
    border:1px solid #002F5D;
}
.gallery-thumb.slick-slider {
    margin: 0 70px;
}
@media screen and (min-width: 768px) {
    .gallery-thumb.has-no-arrows .slick-track {
        margin: 0;
        left: 50% !important;
        transform: translateX(-50%);
    }
}




/* video button */
.gallery .video-play-button {
    font-size: 1px;
    line-height: 60px;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    text-align:center;
    z-index:80;
}
.gallery .video-play-button:after {
    font-family: iconfont;
    content: var(--icon-play);
    color: #002F5D;
    font-size: 25px;
    margin: 0;
    text-shadow: none;
}

.gallery-main .video-play-button {
    line-height: 90px;
    height: 90px;
    width: 90px;
    margin: -45px 0 0 -45px;
}
.gallery-main .video-play-button:after {
    font-size: 44px;
}